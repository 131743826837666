import { FC, useEffect } from 'react'
import { View } from 'react-native'
import ButtonGenerate from './ButtonGenerate'
import Editor from './editor/Editor'
import Toast from 'react-native-toast-message'
import { useStore } from '@/shared/store'
import { useSendGenerateMessage, useSendMessage } from '@/components/screens/inbox/api'
import { useRoute } from '@react-navigation/native'

const Body: FC = () => {
    const router = useRoute()
    const { sendMessage } = useSendMessage()
    const { sendGenerateMessage } = useSendGenerateMessage()
    const {
        sendMessageForm,
        setSendMessageForm,
        validateField,
        noValidEmail,
        currentThreadChat,
        showCompose,
        noValidPrompt,
        clearValidateField
    } = useStore((state) => state)

    useEffect(() => {
        noValidPrompt && clearValidateField('prompt')
        noValidEmail && clearValidateField('email')
    }, [noValidPrompt, noValidEmail, clearValidateField, router])

    const onSubmit = () => {
        if (showCompose) {
            if (!!sendMessageForm.to && !!sendMessageForm.text) {
                validateField(sendMessageForm.to, 'email')
                !noValidEmail && sendMessage(sendMessageForm)
                Toast.show({
                    type: 'success',
                    text1: 'Reply Box is Ready',
                    text2: 'Your message has been successfully sent.'
                })
            } else {
                validateField('', 'email')
                Toast.show({
                    type: 'error',
                    text1: 'Reply box is empty'
                })
            }
        } else {
            if (!!currentThreadChat.senderAddress && !!sendMessageForm.text) {
                sendMessage(sendMessageForm)
                Toast.show({
                    type: 'success',
                    text1: 'Reply Box is Ready',
                    text2: 'Your message has been successfully sent.'
                })
            } else {
                Toast.show({
                    type: 'error',
                    text1: 'Reply box is empty'
                })
            }
        }
    }

    const onGenerate = () => {
        if (!!sendMessageForm.prompt) {
            validateField(sendMessageForm.prompt, 'prompt')
            sendGenerateMessage(sendMessageForm)
            Toast.show({
                type: 'success',
                text1: 'Reply Box is Ready',
                text2: 'Generation request sent. Please wait for completion.'
            })
            return
        }
        validateField('', 'prompt')
        Toast.show({
            type: 'error',
            text1: 'Please enter the prompt.'
        })
        return
    }

    return (
        <View className='flex-col flex-1'>
            <ButtonGenerate
                onGenerate={onGenerate}
                value={sendMessageForm.prompt || ''}
                onChange={setSendMessageForm}
            />
            <Editor value={sendMessageForm.text || ''} setValue={setSendMessageForm} onSubmit={onSubmit} />
        </View>
    )
}

export default Body
