import { FC, useState } from 'react'
import { View } from 'react-native'
import { Button, ButtonPlain, Dropdown, InputSearch } from '@/components/ui'
import cn from 'clsx'
import { useTypedNavigation } from '@/hooks/useTypedNavigation'
import { useMailPosition } from '@/context/MailPositionContext'
import { Pressable } from 'react-native-web-hover'
import { useTypedRoute } from '@/hooks/useTypedRoute'
import { useColors } from '@/hooks/useColors'
import { useStore } from '@/shared/store'

interface IProps {
    selectedLetters: number
    countLetters: number
    selectLetters: () => void
}

const MailHeader: FC<IProps> = ({ selectLetters, selectedLetters, countLetters }) => {
    const { bg, text, color } = useColors()
    const { params } = useTypedRoute<'Inbox'>()
    const path = params?.path
    const isActiveChat = params?.id
    const { navigate } = useTypedNavigation()
    const { position, setPosition } = useMailPosition()
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [valueType, setValueType] = useState('')

    const { showCompose, setShowCompose } = useStore((state) => state)

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible)
    }
    const closeDropdown = () => {
        setDropdownVisible(false)
    }

    const togglePosition = () => {
        navigate('Inbox', { path: path, id: null })
        setPosition('horizontal')
    }
    return (
        <View className={cn('flex-row justify-between items-center relative z-20 mb-2')}>
            <View className='flex-row justify-between items-center ' style={{ gap: 24 }}>
                <View className='ml-[2px]'>
                    {!!isActiveChat && position === 'horizontal' ? (
                        <Button
                            icon='MoveLeft'
                            sizeIcon={20}
                            colorIcon={color.black}
                            className='p-base-x2 rounded-base-x2'
                            styleOnHover={bg.hoverIconSurface}
                            onPress={() => navigate('Inbox', { path: path, id: null })}
                        />
                    ) : (
                        <Pressable>
                            {({ hovered }) => (
                                <View
                                    style={{
                                        //@ts-ignore
                                        transition: 'all 0.3s ease-out'
                                    }}
                                    className={cn(
                                        'flex-row items-center rounded-base-x2',
                                        (hovered || valueType || selectedLetters > 0) && bg.hoverIconSurface
                                    )}>
                                    <Button
                                        icon={
                                            selectedLetters === countLetters
                                                ? 'CheckSquare'
                                                : selectedLetters < countLetters && selectedLetters
                                                ? 'MinusSquare'
                                                : !selectedLetters
                                                ? 'Square'
                                                : ''
                                        }
                                        sizeIcon={20}
                                        colorIcon={color.black}
                                        className='p-base-x2'
                                        onPress={selectLetters}
                                    />
                                    <Dropdown
                                        left={-35}
                                        setValue={setValueType}
                                        variant='body'
                                        isVisible={dropdownVisible}
                                        onClose={closeDropdown}
                                        options={
                                            path === 'spam'
                                                ? [
                                                      { text: 'All' },
                                                      { text: 'Read' },
                                                      { text: 'Unread' },
                                                      { text: 'Important' },
                                                      { text: 'Not important' },
                                                      { text: 'Spam' },
                                                      { text: 'All not spam' }
                                                  ]
                                                : [
                                                      { text: 'All' },
                                                      { text: 'Read' },
                                                      { text: 'Unread' },
                                                      { text: 'Important' },
                                                      { text: 'Not important' }
                                                  ]
                                        }
                                        button={
                                            <Button
                                                onPress={toggleDropdown}
                                                icon='ChevronDown'
                                                colorIcon={color.black}
                                                sizeIcon={20}
                                                className='p-base-x2'
                                            />
                                        }
                                    />
                                </View>
                            )}
                        </Pressable>
                    )}
                </View>
                {isActiveChat && (
                    <View className='flex-row items-center' style={{ gap: 8 }}>
                        {[undefined, 'sent'].includes(path) && (
                            <>
                                <Button
                                    icon='MailOpen'
                                    sizeIcon={20}
                                    colorIcon={color.black}
                                    className='p-base-x2 rounded-base-x2'
                                    styleOnHover={bg.hoverIconSurface}
                                />
                                <Button
                                    icon='AlertOctagon'
                                    colorIcon={color.black}
                                    sizeIcon={20}
                                    className='p-base-x2 rounded-base-x2'
                                    styleOnHover={bg.hoverIconSurface}
                                />
                                <Button
                                    icon='Trash2'
                                    colorIcon={color.black}
                                    sizeIcon={20}
                                    className='p-base-x2 rounded-base-x2'
                                    styleOnHover={bg.hoverIconSurface}
                                />
                            </>
                        )}
                        {path === 'sent' && (
                            <Button
                                icon='FolderSymlink'
                                colorIcon={color.black}
                                sizeIcon={20}
                                className='p-base-x2 rounded-base-x2'
                                styleOnHover={bg.hoverIconSurface}
                            />
                        )}
                        {path === 'spam' && (
                            <>
                                <ButtonPlain>Not spam</ButtonPlain>
                                <ButtonPlain>Delete forever</ButtonPlain>
                                <Button
                                    icon='MailCheck'
                                    colorIcon={color.black}
                                    sizeIcon={20}
                                    className='p-base-x2 rounded-base-x2'
                                    styleOnHover={bg.hoverIconSurface}
                                />
                            </>
                        )}
                        {path === 'trash' && (
                            <>
                                <ButtonPlain>Delete forever</ButtonPlain>
                                <Button
                                    icon='MailCheck'
                                    colorIcon={color.black}
                                    sizeIcon={20}
                                    className='p-base-x2 rounded-base-x2'
                                    styleOnHover={bg.hoverIconSurface}
                                />
                                <Button
                                    icon='AlertOctagon'
                                    colorIcon={color.black}
                                    sizeIcon={20}
                                    className='p-base-x2 rounded-base-x2'
                                    styleOnHover={bg.hoverIconSurface}
                                />
                            </>
                        )}
                    </View>
                )}
                {!isActiveChat && path === 'spam' && (
                    <Button
                        icon='RefreshCcw'
                        colorIcon={color.black}
                        sizeIcon={20}
                        className='p-base-x2 rounded-base-x2'
                        styleOnHover={bg.hoverIconSurface}
                    />
                )}
                <InputSearch
                    onChange={() => undefined}
                    value={''}
                    placeholder='Search mail'
                    className='w-[440px]'
                />
            </View>
            <View className='flex-row' style={{ gap: 8 }}>
                <Button
                    icon='Pencil'
                    sizeIcon={20}
                    styleOnHover='bg-sky-500'
                    onPress={() => setShowCompose(true)}
                    variant='button-default'
                    disabled={showCompose}
                    colorIcon={showCompose ? color.inactiveIcons : color.black}
                    className={cn(
                        'bg-sky pl-base-x3 pr-base-x4 rounded-base-x3',
                        showCompose && bg.inactiveSurface
                    )}
                    classNameText={cn(showCompose ? text.disabledText : text.white)}>
                    Compose
                </Button>
                {position === 'vertical' ? (
                    <Button
                        icon='LayoutList'
                        sizeIcon={24}
                        colorIcon={color.black}
                        styleOnHover={bg.hoverIconSurface}
                        className='p-base-x2 rounded-base-x2'
                        onPress={() => togglePosition()}
                    />
                ) : (
                    <Button
                        icon='Menu'
                        sizeIcon={24}
                        colorIcon={color.black}
                        styleOnHover={bg.hoverIconSurface}
                        className='p-base-x2 rounded-base-x2'
                        onPress={() => setPosition('vertical')}
                    />
                )}
            </View>
        </View>
    )
}

export default MailHeader
