import { FC, useEffect, useState } from 'react'
import { Image, View } from 'react-native'
import { Button, Typography } from '@/components/ui'
import { useColors } from '@/hooks/useColors'
import cn from 'clsx'
import { useColorScheme } from 'nativewind'

interface IProps {
    title: string
    sender: string
    senderAddress: string
    date: string
}

const Header: FC<IProps> = ({ title, sender, senderAddress, date }) => {
    const [currentConversation, setCurrentConversation] = useState<number>(1)
    const [conversationsCount, setConversationsCount] = useState<number>(9)
    const [activeArrow, setActiveArrow] = useState<'prew' | 'next' | 'all' | ''>('')

    const { colorScheme } = useColorScheme()
    const { text, color, bg } = useColors()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

    const checkCurrentConversation = (current: number, count: number) => {
        if (current === 0 && count === 0) return setActiveArrow('')
        if (current === 1) return setActiveArrow('next')
        if (current === count) return setActiveArrow('prew')

        return setActiveArrow('all')
    }

    useEffect(() => {
        checkCurrentConversation(currentConversation, conversationsCount)
    }, [currentConversation, conversationsCount])

    const handleActiveArrow = (arrowState: 'prew' | 'next') => {
        if (currentConversation !== 0 && conversationsCount !== 0) {
            arrowState === 'next'
                ? setCurrentConversation(
                      currentConversation < conversationsCount ? currentConversation + 1 : conversationsCount
                  )
                : setCurrentConversation(currentConversation > 1 ? currentConversation - 1 : 1)
        }
    }

    return (
        <View className={cn('pt-base-x1 justify-between flex-col border-b overflow-hidden ', deviderColor)}>
            <View
                className={cn('flex-1 flex-row items-center px-base-x4 border-b', deviderColor)}
                style={{ gap: 8 }}>
                <Typography variant='h3' className={cn('flex-grow', text.inactiveText)}>
                    Related Conversations
                </Typography>
                <View className='flex flex-row items-center' style={{ gap: 8 }}>
                    <Typography variant='body' className={cn('px-4 text-xs', text.inactiveText)}>
                        {currentConversation} of {conversationsCount}
                    </Typography>
                    <View className='flex flex-row' style={{ gap: 8 }}>
                        <Button
                            className='p-1.5 rounded-md'
                            icon='ChevronLeft'
                            styleOnHover={bg.hoverIconSurface}
                            colorIcon={
                                activeArrow !== 'prew'
                                    ? activeArrow === 'all'
                                        ? color.black
                                        : color.inactiveIcons
                                    : color.black
                            }
                            sizeIcon={24}
                            onPress={() => handleActiveArrow('prew')}
                        />
                        <Button
                            className='p-1.5 rounded-md'
                            icon='ChevronRight'
                            styleOnHover={bg.hoverIconSurface}
                            colorIcon={
                                activeArrow !== 'next'
                                    ? activeArrow === 'all'
                                        ? color.black
                                        : color.inactiveIcons
                                    : color.black
                            }
                            sizeIcon={24}
                            onPress={() => handleActiveArrow('next')}
                        />
                    </View>
                </View>
            </View>
            <View
                className='px-base-x4 pt-base-x3 pb-base-x2 flex-row justify-between items-center'
                style={{ gap: 16 }}>
                <View className='flex-row items-center' style={{ gap: 16 }}>
                    <Typography
                        variant='button-default'
                        className={cn(
                            text.boldText,
                            'bg-important, flex-1 max-w-[250px]  desktop:max-w-[330px] font-bold '
                        )}
                        nowrap
                        numberOfLines={1}
                        ellipsizeMode='tail'>
                        {sender}
                    </Typography>

                    <Typography
                        variant='calout'
                        className='text-light-text max-w-[166px]  '
                        nowrap
                        numberOfLines={1}
                        ellipsizeMode='tail'>
                        {senderAddress}
                    </Typography>
                </View>

                <Typography variant='label-date' className={cn(text.black, 'min-w-fit')}>
                    {date}
                </Typography>
            </View>
            <View
                className={cn('px-base-x4 py-0.5 flex-row items-center border-b', deviderColor)}
                style={{ gap: 8 }}>
                <Typography variant='h4' className={cn('font-bold leading-7', text.boldText)}>
                    {title}
                </Typography>
                <Image
                    source={require('@/assets/icons/important.svg')}
                    tintColor={'active' ? '#FDBA74' : colorScheme === 'light' ? '#D1D5DB' : '#374151'}
                    className='w-[15px] h-[14px] m-base-x2'
                />
            </View>
        </View>
    )
}

export default Header
