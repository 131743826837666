import { FC, PropsWithChildren } from 'react'
import type { TypeNavigate } from './menu.interface'
import { Button } from '@/components/ui'
import { TypeRootStackParamList } from '@/navigation/navigation.types'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'

interface IMenuItemProps {
    iconName?: string
    path: keyof TypeRootStackParamList
    nav: TypeNavigate
    currentRoute?: string
    noHover?: boolean
    onHoverIn?: () => void
    onHoverOut?:()=> void
}

const MenuItem: FC<PropsWithChildren<IMenuItemProps>> = ({
	currentRoute,
	iconName,
	path,
	nav,
	noHover,
	children,
	onHoverIn,
	onHoverOut
}) => {
	const isActive = currentRoute === path
	const { color } = useColors()
	return (
		<Button
			onHoverIn={onHoverIn}
			onHoverOut={onHoverOut}
            icon={iconName}
            onPress={() => nav(path)}
            className={cn(    
                'rounded-base-x3 p-base-x2',
                isActive && !noHover && 'bg-black'
            )}
            styleOnHover={!noHover ? 'bg-gray-400' : ''}
            sizeIcon={24}
            colorIcon={isActive ? 'white' : color.inactiveIcons}
            styleColorIconOnHover='black'
            noMargin={!iconName}>
			{!iconName && children}
        </Button>
    )
}

export default MenuItem
