import { useQuery } from "@tanstack/react-query"
import { TodosService } from "@/services/togos";
import { useStore } from "@/shared/store/store";
import { useEffect, useState } from "react";

export const useGetTodos = () => {
	const [enabled, setEnabled] = useState<boolean>(false)
	const { setTodos, todos, todosTab, refetchTodos, setRefetchTodos } = useStore((state) => state)
   
	const queryResult = useQuery(
		['get_todos'],
		() => TodosService.getTodos(),
		{
			enabled,
			onSuccess(data) {
				setRefetchTodos(false)
				setTodos(data)
			}
		}
	);

	
	useEffect(() => {
		refetchTodos && fetchTodos()
    }, [todos])
	
	useEffect(() => {
        if (enabled) {
            setEnabled(false)
        }
    }, [enabled])
	
	
	const fetchTodos = () => {
		setEnabled(true)
	}

    return {
		todos,
		todosTab,
		fetchTodos,
        ...queryResult,
    }
}