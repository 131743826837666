import { useMutation } from "@tanstack/react-query"
import { TodosService } from "@/services/togos";
import { useState } from "react";
import { ITodo } from "@/shared/types/todo.interface";
import { useStore } from "@/shared/store";



export const useUpdateTodo = () => {
	const [todo, setTodo] = useState<ITodo | null>(null);
	const [todoId, setTodoId] = useState<number | null>(null);

	const { updateTodo: updateTodoStore } = useStore(state => state)

    const mutationResult = useMutation(
		['update_todos'],
		() => TodosService.updateTodo(todoId, todo),
		{
			onSuccess(data) {
				console.log('UPDATE TODO:', data)
				updateTodoStore(todoId as number, todo)
			}
		}
	);

	const updateTodo = async (id: number | null, todoData: ITodo ) => {
		const newTodo: ITodo = {
			id: id as number,
			state: todoData.state,
			text: todoData.text,
			text_extra: todoData.text_extra,
			is_favorite: todoData.is_favorite || false,
			priority: todoData.priority || 'low',
			parent_id: todoData.parent_id || null,
			due: todoData.due || null,
		}
		await setTodo(newTodo)
		await setTodoId(id)
		await mutationResult.mutate()
	}


    return {
		updateTodo,
		todoId,
		setTodoId,
        ...mutationResult,
    }
}