import { FC, useEffect, useState } from 'react'
import { View } from 'react-native'
import ModalButtons from './ModalButtons'
import { Button, ButtonPlain, Checkbox, Typography } from '@/components/ui'
import ModalStep from '@/components/screens/dashboard/components/modals/ModalStep'
import { getAgreeTerms, setAgreeTerms } from '@/services/app.helper'

interface TProps {
	setStep: (step: number) => void
	step: number
}
const ModalContainer: FC<TProps> = ({ step, setStep }) => {
	const [agree, setAgree] = useState(false)
	const [errorCheckbox, setErrorCheckbox] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			const isAgree = await getAgreeTerms()
			setAgree(isAgree)
		}

		fetchData()
	}, [step])

	useEffect(() => {
		setErrorCheckbox(false)

		const fetchData = async () => {
			await setAgreeTerms(agree)
		}

		fetchData()
	}, [agree])

	const onSkip = () => {
		if (!agree) {
			setErrorCheckbox(true)
			return
		}

		setStep(5)
	}
	const onContinue = () => {
		if (!agree) {
			setErrorCheckbox(true)
			return
		}

		setStep(step + 1)
	}
	return (
		<>
			<ModalButtons visible={step === 0} style='top-[50%] left-[50%]'>
				<View className='flex-col items-center' style={{ gap: 64 }}>
					<View
						className='flex-col bg-white rounded-base-x4 w-[565px] p-base-x8'
						style={{ gap: 16 }}
					>
						<Typography variant='h3' className='text-black text-center'>
							Welcome, Username!
							<View className='flex mb-base-x1' />
							Meet Dashboard: Your Interactive Email Hub.
						</Typography>
						<Typography variant='body' className='text-gray-900 text-center'>
							We’ll guide you through the features in just four clicks.
						</Typography>
					</View>
					<View
						className='flex-col justify-center items-center'
						style={{ gap: 16 }}
					>
						<View className='flex-row items-center' style={{ gap: 24 }}>
							<ButtonPlain
								classNameText='text-gray-900'
								className='w-[202px]'
								onPress={onSkip}
							>
								Skip, I will explore alone
							</ButtonPlain>
							<Button
								className='flex items-center justify-center pr-base-x8 pl-base-x8 pt-base-x4 pb-base-x4 bg-black rounded-base-x3'
								styleOnHover='bg-sky'
								classNameText='text-white'
								variant='button-default'
								onPress={onContinue}
							>
								Nice, Let’s see
							</Button>
						</View>
						<Checkbox
							error={errorCheckbox}
							form='square'
							text='I agree with the terms and conditions'
							checked={agree}
							onChange={value => setAgree(value)}
						/>
					</View>
				</View>
			</ModalButtons>
			<ModalStep
				style='top-[100px] left-[50%]'
				directionArrow='left'
				styleModal={{ transform: [{ translateX: '-50%' }] }}
				text='AI reads all your new emails and gives a clear snapshot of what’s inside. Click on any part to see details, suggestions, and the chat!'
				step='1'
				visible={step === 1}
			/>
			<ModalStep
				directionArrow='left'
				style='top-[50%] left-[50%]'
				styleModal={{
					transform: [{ translateX: '-50%' }, { translateY: '-50%' }]
				}}
				text='This section shows more detailed highlights of the email you ve selected.'
				step='2'
				visible={step === 1}
			/>
			<ModalStep
				directionArrow='left'
				style='bottom-[100px] left-[50%]'
				styleModal={{ transform: [{ translateX: '-50%' }] }}
				text='Here, the AI suggests what you can add to your to-do list from this email.'
				step='3'
				visible={step === 1}
			/>
			<ModalStep
				style='top-[30%] left-[15%] w-[225px]'
				directionArrow='right'
				styleModal={{
					transform: [{ translateX: '-50%' }, { translateY: '-50%' }]
				}}
				text='This section shows more detailed highlights of the email you ve selected.'
				step='4'
				visible={step === 2}
			/>
			<ModalStep
				directionArrow='right'
				style='left-[15%] bottom-[-20px] w-[225px]'
				styleModal={{
					transform: [{ translateX: '-50%' }, { translateY: '-50%' }]
				}}
				text='Here, you can generate a response with AI, choose the tone and length, edit or simply check the grammar. Example: "Tell them I am busy; let is meet tomorrow." Or, you can always craft a perfect answer yourself.'
				step='5'
				visible={step === 2}
			/>
			<ModalButtons visible={step === 1 || step === 2} style='right-[20px]'>
				<View className='flex-col items-center' style={{ gap: 24 }}>
					<Button
						className='flex items-center justify-center pr-base-x8 pl-base-x8 pt-base-x4 pb-base-x4 bg-black rounded-base-x3'
						styleOnHover='bg-sky'
						classNameText='text-white'
						variant='button-default'
						onPress={onContinue}
					>
						Ok, got it
					</Button>
					<ButtonPlain
						classNameText='text-gray-900'
						className='w-[202px]'
						onPress={onSkip}
					>
						Skip, I will explore alone
					</ButtonPlain>
				</View>
			</ModalButtons>
			<ModalStep
				directionArrow='right'
				style='top-[30%] right-[30%] w-[260px]'
				styleModal={{
					transform: [{ translateY: '-50%' }]
				}}
				text='Enjoy quick access to the task list—there is also a dedicated page for it.'
				step='6'
				visible={step === 3}
			/>
			<ModalStep
				directionArrow='right'
				style='right-[30%] bottom-[40px] w-[260px]'
				styleModal={{
					transform: [{ translateY: '-50%' }]
				}}
				text='Key points from the email thread if you need a recap'
				step='7'
				visible={step === 3}
			/>
			<ModalButtons visible={step === 3} style='top-[50%] left-[50%]'>
				<View className='flex-col items-center' style={{ gap: 24 }}>
					<Button
						className='flex items-center justify-center pr-base-x8 pl-base-x8 pt-base-x4 pb-base-x4 bg-black rounded-base-x3'
						styleOnHover='bg-sky'
						classNameText='text-white'
						variant='button-default'
						onPress={onSkip}
					>
						Let’s start!
					</Button>
					<ButtonPlain
						classNameText='text-gray-900'
						className='w-[300px]'
						onPress={() => setStep(1)}
					>
						I want to take the survey once again
					</ButtonPlain>
				</View>
			</ModalButtons>
		</>
	)
}

export default ModalContainer
