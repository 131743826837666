import { FC } from 'react'
import { View } from 'react-native'
import { Typography } from '@/components/ui'
import Header from './components/Header'
import Content from './components/Content'
import { TPath } from '../../inbox.interface'
import cn from 'clsx'

interface IProps {
	activeChat: any
	path: TPath
}

const MailChat: FC<IProps> = ({ activeChat, path }) => {
	const body = {
		title: 'Find and pay for a ticket through Scive AI ge...',
		sender: 'Scive Daily Digest lon...',
		senderAddress: '<noreply-long-sender...>',
		date: '7:20 AM (3 hours ago)'
	}

	return (
		<View className={cn('flex-1')}>
			{!activeChat ? (
				<Typography
					variant='body'
					className='text-center text-gray-400 pt-base-x9 '
				>
					No conversations selected
				</Typography>
			) : (
				<View
					className={cn('pb-base-x2 relative rounded-base-x4 flex-1 justify-between')}
				>
					<Header
						title={body.title}
						sender={body.sender}
						senderAddress={body.senderAddress}
						date={body.date}
					/>
					<Content path={path} />
				</View>
			)}
		</View>
	)
}

export default MailChat
