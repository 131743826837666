import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useStore } from '@/shared/store'
import { ThreadsService } from '@/services/inbox/threads.api'
import { IMessageForm } from '@/shared/store/types'
import { ISendMessage } from '@/shared/types/dashboard.interface'
import { useUserProfile } from '../../settings/api'
import { htmlToText } from '@/utils/htmlToText'

export const useSendMessage = () => {
    const [message, setMessage] = useState<ISendMessage>({
        to_addr: '',
        from_addr: '',
        subject: '',
        content: ''
    })
    const { clearSendMessageForm, showCompose, currentThreadChat } = useStore((state) => state)
    const { userProfile } = useUserProfile()

    const mutationResult = useMutation(['send_message'], () => ThreadsService.sendMessage(message), {
        onSuccess(res) {
            console.log(res)
            if (res.id) {
                clearSendMessageForm()
            }
            console.log('Message sent successfully', res)
        }
    })

    const sendMessage = async (data: IMessageForm) => {
        const to_addr = showCompose ? (data.to as string) : currentThreadChat.senderAddress
        const subject = showCompose ? (data.subject as string) : currentThreadChat.title
        const content = htmlToText(data.text as string)

        console.log(content)
        if (!!userProfile?.email) {
            const newMessage = {
                to_addr,
                subject,
                from_addr: userProfile.email,
                content
            }
            console.log(newMessage)
            await setMessage({ ...newMessage })
            await mutationResult.mutate()
        }
    }

    return {
        sendMessage,
        ...mutationResult
    }
}
