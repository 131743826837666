import { useQuery } from "@tanstack/react-query";
import { ThreadsService } from "@/services/inbox/threads.api";
import { useStore } from "@/shared/store";


export const useGetThreads = (enabled = false) => {
	const { threads, setThreads } = useStore((state) => state)

    const queryResult = useQuery(
		['get_threads'],
		() => ThreadsService.getThreads(),
		{
            enabled,
			onSuccess(res) {
				res.threads ? setThreads([...res.threads]) : setThreads([]) 
			}
		}
	);
    return {
		threads,
        ...queryResult,
    }
}