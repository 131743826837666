import { FC } from 'react'
import { ScrollView, View } from 'react-native'
import { ButtonPlain, Typography } from '@/components/ui'

const OrderTable: FC = () => {
	const table = [
		{
			id: 0,
			date: 'Apr.27.2023',
			type: 'Pro monthly',
			amount: '$20',
			receipt: '/url'
		},
		{
			id: 1,
			date: 'Apr.27.2023',
			type: 'Pro monthly',
			amount: '$5',
			receipt: '/url'
		},
		{
			id: 2,
			date: 'Apr.27.2023',
			type: 'Pro monthly',
			amount: '$5',
			receipt: '/url'
		},
		{
			id: 3,
			date: 'Apr.27.2023',
			type: 'Pro monthly',
			amount: '$20',
			receipt: '/url'
		},
		{
			id: 1,
			date: 'Apr.27.2023',
			type: 'Pro monthly',
			amount: '$5',
			receipt: '/url'
		},
		{
			id: 2,
			date: 'Apr.27.2023',
			type: 'Pro monthly',
			amount: '$5',
			receipt: '/url'
		},
		{
			id: 3,
			date: 'Apr.27.2023',
			type: 'Pro monthly',
			amount: '$20',
			receipt: '/url'
		},
		{
			id: 1,
			date: 'Apr.27.2023',
			type: 'Pro monthly',
			amount: '$5',
			receipt: '/url'
		},
		{
			id: 2,
			date: 'Apr.27.2023',
			type: 'Pro monthly',
			amount: '$5',
			receipt: '/url'
		},
		{
			id: 3,
			date: 'Apr.27.2023',
			type: 'Pro monthly',
			amount: '$20',
			receipt: '/url'
		}
	]

	return (
        <View className='w-[648px] h-[412px] rounded-b-base-x4 '>
            <View
                className='items-center flex-row justify-between rounded-tl-base-x4 rounded-tr-base-x4 bg-gray-200 h-base-x8 '
                style={{ gap: 16 }}>
                <Typography variant='body-list' className='text-black w-[200px] text-center'>
                    Date
                </Typography>
                <Typography variant='body-list' className='text-black w-[127px] text-center'>
                    Type
                </Typography>
                <Typography variant='body-list' className='text-black w-[127px] text-center'>
                    Amount
                </Typography>
                <Typography variant='body-list' className='text-black w-[140px] text-center'>
                    Receipt
                </Typography>
            </View>
            <ScrollView
                contentInsetAdjustmentBehavior='automatic'
                className='bg-gray-50 max-h-[380px] rounded-b-base-x4 '>
                {!table.length && (
                    <Typography
                        variant='body'
                        className='text-gray-400 pl-base-x6 flex items-center h-[44px] border-b border-gray-300 '>
                        No previous orders
                    </Typography>
                )}
                <View
                    className='flex-col pt-base-x4 pb-base-x4 justify-between items-center  '
                    style={{ gap: 16 }}>
                    {table.length > 0 &&
                        table.map((row) => (
                            <View
                                key={row.id}
                                className='flex-row justify-between items-center h-base-x9 '
                                style={{ gap: 16 }}>
                                <Typography
                                    variant='body-bold'
                                    className='text-gray-900 w-[200px] text-center '>
                                    {row.date}
                                </Typography>
                                <Typography
                                    variant='body-list'
                                    className='text-gray-900 w-[127px] text-center '>
                                    {row.type}
                                </Typography>
                                <Typography
                                    variant='body-list'
                                    className='text-gray-900 w-[127px] text-center '>
                                    {row.amount}
                                </Typography>
                                <ButtonPlain
                                    classNameText='text-sky'
                                    className='justify-between w-[140px] text-center px-[38px]'>
                                    Download
                                </ButtonPlain>
                            </View>
                        ))}
                </View>
            </ScrollView>
        </View>
    )
}

export default OrderTable
