import { FC, useEffect } from 'react'
import { View } from 'react-native'
import { INavbar } from './navbar.interface'
import { Typography } from '@/components/ui'
import NavbarItem from './NavbarItem'
import { useColors } from '@/hooks/useColors'
import cn from 'clsx'
import { useColorScheme } from 'nativewind'
import { useStore } from '@/shared/store'
import { useRoute } from '@react-navigation/native'
import { TTodoCategories } from '@/shared/store/types'

const Navbar: FC<INavbar> = ({ navbar, title }) => {
	const { text } = useColors()

	const { colorScheme } = useColorScheme()
	const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

    // @ts-ignore
    const { params } = useRoute<{path: string | undefined}>()
    const { setTodosTabCategory, filteredTodosTabCurrent } = useStore()

	useEffect(() => {
		!filteredTodosTabCurrent && setTodosTabCategory(params?.path  as TTodoCategories)
	}, [filteredTodosTabCurrent])

	return (
        <View
            className={cn(
                'pl-base-x2 pr-base-x2 py-base-x2 w-[220px] desktop:w-[220px] border-r-[1px] ',
                deviderColor
            )}>
            {title ? (
                <Typography variant='h3' className={cn('pb-base-x4 ml-1', text.black)}>
                    {title}
                </Typography>
            ) : (
                <Typography variant='body' className={cn('text-center pb-base-x4', text.inactiveText)}>
                    25 December Wed.
                </Typography>
            )}

            <View className='flex-col' style={{ gap: 8 }}>
                {navbar.map((item) => (
                    <NavbarItem
                        key={item.title}
                        navItem={item}
                    />
                ))}
            </View>
        </View>
    )
}
export default Navbar
