import { FC } from 'react'
import { View } from 'react-native'
import { IMessageBubble } from './messages.interface'
import { Typography } from '@/components/ui'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'

const MessageBubble: FC<IMessageBubble> = ({
	text,
	sender,
	date,
	showAvatar,
	showDate
}) => {
	const { bg, text: textColor } = useColors()
	return (
		<View
			className={cn(
				'flex-col items-start',
				sender === 'I' && 'ml-auto flex-col'
			)}
			style={{ gap: 8 }}
		>
			{showAvatar && (
				<View
					className={cn(
						'rounded-base-x16 bg-gray-400 items-center justify-center w-[44px] h-[44px]',
						sender === 'I' && 'ml-auto'
					)}
				>
					<Typography variant='h2' className='text-white'>
						A
					</Typography>
				</View>
			)}
			<View
				className={cn(
					'flex-col overflow-hidden max-w-[524px]',
					sender === 'I' && 'items-end'
				)}
			>
				<Typography
					variant='body'
					className={cn(
						'p-base-x2 rounded-base-x4',
						textColor.boldText,
						sender === 'I' ? bg.outcomingMessage : bg.inactiveSurface
					)}
				>
					{text}
				</Typography>
				{showDate && (
					<Typography
						variant='body'
						className={cn('pl-base-x2 pr-base-x2', textColor.disabledText)}
					>
						{date}
					</Typography>
				)}
			</View>
		</View>
	)
}

export default MessageBubble
