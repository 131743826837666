import { FC, PropsWithChildren, useEffect } from 'react'
import { Modal, Pressable, View } from 'react-native'
import { IModal } from './modal.interface'
import { useModal } from '@/context/ModalContext'
import { Button, ButtonPlain, Typography } from '@/components/ui'

const Dialog: FC<PropsWithChildren<IModal>> = ({
	visible,
	onClose,
	title,
	buttonReset,
	buttonSubmit,
	onClickButtonSubmit,
	onClickButtonReset,
	children
}) => {
	const { setVisible, visible: containerVisible } = useModal()

	useEffect(() => {
		setVisible(visible)
	}, [visible])

	useEffect(() => {
		if (!containerVisible) {
			onClose()
		}
	}, [containerVisible])

	return (
		<Modal
			animationType='fade'
			transparent={true}
			visible={visible}
			onRequestClose={() => onClose()}
		>
			<Pressable onPress={() => setVisible(false)}>
				<View className='absolute top-0 left-0 right-0 bottom-0 bg-gray-900 opacity-40 z-[999]' />
			</Pressable>
			<View
				className='flex-1 pt-base-x4 pb-base-x4 pr-base-x6 pl-base-x6 justify-center items-center bg-white rounded-base-x4 w-[322px] absolute left-[50%] top-[50%] z-[1000]'
				style={{ transform: [{ translateX: '-50%' }, { translateY: '-50%' }] }}
			>
				<Typography variant='h4' className='text-black p-base-x2'>
					{title}
				</Typography>
				{children}
				<View
					className='flex-row items-center justify-center'
					style={{ gap: 12 }}
				>
					{!!buttonReset && (
						<ButtonPlain className='w-[105px]' onPress={onClickButtonReset}>
							{buttonReset}
						</ButtonPlain>
					)}
					<Button
						className='h-base-x9 bg-sky rounded-[12px] w-[130px] flex items-center justify-center'
						styleOnHover='bg-sky-500'
						classNameText='text-white'
						variant='button-default'
						onPress={onClickButtonSubmit}
					>
						{buttonSubmit}
					</Button>
				</View>
			</View>
		</Modal>
	)
}

export default Dialog
