import {
	LinkingOptions,
	NavigationContainer,
	useNavigationContainerRef
} from '@react-navigation/native'
import { FC, useEffect, useState } from 'react'
import { useCheckAuth } from '@/providers/auth/useCheckAuth'
import PrivateNavigator from './PrivateNavigator'
import { Sidebar } from '@/components/ui'
import { useAuth } from '@/hooks/useAuth'
import cn from 'clsx'
import { StyleSheet, View } from 'react-native'
import { useColors } from '@/hooks/useColors'
import { useUserProfile } from '@/components/screens/settings/api'

const Navigation: FC = () => {
	// const { user } = useAuth()
	const { userProfile } = useUserProfile() 

	const [currentRoute, setCurrentRoute] = useState<string | undefined>(
		undefined
	)
	const { bg } = useColors()
	const linking: LinkingOptions<any> = {
		prefixes: []
	}
	const navRef = useNavigationContainerRef()

	useEffect(() => {
		setCurrentRoute(navRef.getCurrentRoute()?.name)

		const listener = navRef.addListener('state', () =>
			setCurrentRoute(navRef.getCurrentRoute()?.name)
		)

		return () => {
			navRef.removeListener('state', listener)
		}
	}, [])

	useCheckAuth(currentRoute)

	return (
        <>
            <View className={cn('h-base-x9', bg.dashboardSurface)}></View>
            <View className={cn('flex-row', bg.dashboardSurface)} style={styles.container}>
                {userProfile && currentRoute && <Sidebar nav={navRef.navigate} currentRoute={currentRoute} />}
                <View className={cn('flex-1 overflow-hidden', userProfile && ' rounded-tl-base-x6')}>
                    <NavigationContainer ref={navRef} linking={linking}>
                        <PrivateNavigator />
                    </NavigationContainer>
                </View>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
	container: {
		// @ts-ignore
		height: 'calc(100vh - 36px)'
	}
})

export default Navigation
