import { FC } from 'react'
import { Text, View } from 'react-native'
import { Header } from '../index'
import { ButtonPlain, Typography } from '@/components/ui'
import Profile from './components/Profile'
import Selectors from './components/Selectors'
import { saveCourseCompleted } from '@/services/app.helper'
import { useTypedNavigation } from '@/hooks/useTypedNavigation'
import { useColorScheme } from 'nativewind'
import cn from 'clsx'

const Account: FC = () => {
	const { navigate } = useTypedNavigation()

	const { colorScheme } = useColorScheme()
	const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

	const setCourseCompleted = async () => {
		navigate('Dashboard')
		await saveCourseCompleted(false)
	}

	return (
		<View
			className={cn(
				'flex-1 p-base-x2 flex-col border-l-[1px]',
				deviderColor)}
			style={{ gap: 8 }}
		>
			<Header title='Account' />
			<View className='pl-base-x9 pr-base-x9 pt-base-5 flex-col justify-between flex-1'>
				<View className='flex-col' style={{ gap: 64 }}>
					<View className='flex-row items-center' style={{ gap: 52 }}>
						<Profile />
						<Typography variant='body'>
							Current plan: <Text className='font-bold'>Basic</Text>, active
							till <Text className='text-sky font-bold'>09.27.2023</Text>
						</Typography>
					</View>
					<Selectors />
				</View>
				<ButtonPlain className='h-base-x8 mt-auto' onPress={setCourseCompleted}>
					I want to take the Scive Power course again
				</ButtonPlain>
			</View>
		</View>
	)
}

export default Account
