import { FC, useState } from 'react'
import { Image, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import MenuItem from './MenuItem'
import { TypeNavigate } from './menu.interface'
import { useColors } from '@/hooks/useColors'
import cn from 'clsx'
import { useColorScheme } from 'nativewind'

interface ISidebar {
	nav: TypeNavigate
	currentRoute?: string
}

const Sidebar: FC<ISidebar> = props => {
	const { bottom } = useSafeAreaInsets()
	const { colorScheme } = useColorScheme()
	const { bg } = useColors()
	const [isHover, setIsHover] = useState(false)
	const [isInActive, setIsAnactive] = useState(true)
	
	return (
        <View
            className={cn(
                'pt-base-x8 z-50 mb-base-x8 pr-base-x2 pl-base-x2 justify-between items-center w-[56px]',
                bg.dashboardSurface
            )}
            style={{
                paddingBottom: bottom + 5,
                gap: 8
            }}>
            <View className='flex-col' style={{ gap: 8 }}>
                <MenuItem
                    onHoverIn={() => setIsHover(true)}
                    onHoverOut={() => setIsHover(false)}
                    path='Dashboard'
                    {...props}>
                    <Image
                        source={require(
                            `@/assets/icons/logo-sidebar${
                                props.currentRoute !== 'Dashboard'
                                    ? colorScheme === 'dark'
                                        ? '-dark'
										: isHover
											? '-hover'
											: isInActive ? '-inactive' : ''
									:''
                            }.svg`
                        )}
                        className='w-base-x6 h-base-x6'
                    />
                </MenuItem>
                <MenuItem iconName='Mails' path='Inbox' {...props} />
                <MenuItem iconName='ListTodo' path='Todo' {...props} />
            </View>
            <MenuItem path='Settings' {...props} noHover>
                <Image source={require('@/assets/avatar.png')} className='w-base-x10 h-base-x10' />
            </MenuItem>
        </View>
    )
}

export default Sidebar
