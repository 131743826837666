import { FC, useState } from 'react'
import { View } from 'react-native'
import { Pressable as PressableHover } from 'react-native-web-hover' 
import { Button, Typography } from '@/components/ui'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'
import { ITodo } from '@/shared/types/todo.interface'
import { EllipsisVertical } from 'lucide-react'
import MenuMoreSubtask from '@/components/ui/MenuMoreSubtask'

interface IProps {
    subtask: ITodo
    updateTask?: (id: number, todo: ITodo) => void
    onRemove: () => void
}

const SubtasksItem: FC<IProps> = ({ subtask, updateTask, onRemove }) => {
    const [isHovered, setIsHovered] = useState(false)
    const { id, date, state, text, is_favorite, text_extra } = subtask && subtask

    const done = state === 10
    const overdue = date === 'Yesterday'

    const { color, bg, text: textColor } = useColors()
    const { colorScheme } = useColorScheme()
    
    const styles = isHovered ? { zIndex: 1, gap: 8 } : { gap: 8 }
    const hoverStyle = ' items-center border-transparent rounded-full px-[10px] py-[4px] bg-gray-300'

    return (
        <View className='p-base-x2 flex-col flex-1 justify-center items-center' style={styles}>
            <View className='flex-col flex-1 justify-center items-center w-full' style={{ gap: 8 }}>
                <View className='flex-row justify-between items-center w-full' style={{ gap: 8 }}>
                    <Button
                        icon={done ? 'CheckCircle' : 'Circle'}
                        className='p-base-x2 '
                        sizeIcon={16}
                        colorIcon={done ? color.tooltip : color.black}
                        onPress={() =>
                            updateTask &&
                            updateTask(id as number, {
                                ...subtask,
                                state: done ? 0 : 10
                            })
                        }
                    />
                    <Typography
                        variant='body-list'
                        className={cn('pr-base-x2 flex-grow cursor-default', textColor.boldText, overdue && 'text-red')}>
                        {text}
                    </Typography>
                    {/* !CHECK */}
                    <View className='flex-row justify-end items-center relative' style={{ gap: 8 }}>
                        <PressableHover className='max-w-[24px] max-h-[24px]'>
                            {({ hovered }) => (
                                <>
                                    <Button
                                        onHoverIn={() => setIsHovered(true)}
                                        onHoverOut={() => setIsHovered(false)}
                                        className='flex justify-center items-center'
                                        styleOnHover={hoverStyle}>
                                        <EllipsisVertical size={24} />
                                    </Button>
                                    {hovered && (
                                        <MenuMoreSubtask
                                            setIsHovered={setIsHovered}
                                            onRemove={onRemove}
                                            className='absolute'
                                        />
                                    )}
                                </>
                            )}
                        </PressableHover>
                        <Button
                            icon='Star'
                            className='flex justify-center items-center'
                            colorIcon={is_favorite ? color.star : color.black}
                            fillIcon={is_favorite ? (colorScheme === 'light' ? '#1F2937' : '#38BDF8') : ''}
                            sizeIcon={20}
                            onPress={() =>
                                updateTask &&
                                updateTask(id as number, {
                                    ...subtask,
                                    is_favorite: !is_favorite
                                })
                            }
                        />
                    </View>
                </View>

                {!!text_extra && (
                    <Typography
                        style={{ zIndex: -10000 }}
                        variant='caption'
                        className='min-h-base-x9 pr-base-x2 pl-base-x2 text-light-text -z-10'>
                        {text_extra}
                    </Typography>
                )}
                {!!date && (
                    <View
                        className={cn(
                            'pl-base-x3 pr-base-x3 pt-base-x1 pb-base-x1 rounded-base-x16 bg-gray-100 -z-50 ',
                            bg.inactiveSurface
                        )}
                        //@ts-ignore
                        style={{ width: 'fit-content', zIndex: -10000 }}>
                        <Typography
                            variant='label-date'
                            className={cn(
                                'text-center text-gray-500',
                                date === 'Today' && 'text-button',
                                date === 'Yesterday' && 'text-red'
                            )}>
                            {date}
                        </Typography>
                    </View>
                )}
            </View>
        </View>
    )
}

export default SubtasksItem
