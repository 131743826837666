import { FC } from 'react'
import { View } from 'react-native'
import { Button } from '@/components/ui'
import { useColors } from '@/hooks/useColors'

interface TProps {
	onSubmit: () => void
}

const ButtonDouble: FC<TProps> = ({ onSubmit }) => {
	const { text, color } = useColors()
	return (
		<View className='flex-row items-center bg-sky rounded-base-x3 overflow-hidden'>
			<Button
				variant='body'
				classNameText={text.white}
				styleOnHover='bg-sky-500'
				onPress={onSubmit}
				className='pl-base-x8 pr-base-x12 pt-base-x2 pb-base-x2'
			>
				Send
			</Button>
			<Button
				className='pl-base-x2 pt-base-x2 pb-base-x2 pr-base-x4 border-l border-[#0284C7]'
				icon='Clock4'
				styleOnHover='bg-sky-500'
				sizeIcon={24}
				colorIcon={color.white}
			/>
		</View>
	)
}

export default ButtonDouble
