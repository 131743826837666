import { Button, Icon } from '@/components/ui'
import { Dispatch, FC, useEffect, useState } from 'react'
import { useColorScheme } from 'nativewind'
import cn from 'clsx'
import DateTimePicker, { DateType } from 'react-native-ui-datepicker'
import { TextInput, View } from 'react-native'
import { useStore } from '@/shared/store'
import { TAddTodoType } from '@/shared/store/types'
import { useAddTodo } from '../../todo/api'

interface AddTaskProps {
    showAddTaskModal: any
    icon?: string | React.ReactNode
    className?: string
    textAlign?: 'right' | 'left' | 'center' | undefined
    setShowAddTaskModal: Dispatch<React.SetStateAction<boolean>>
    type: TAddTodoType
}

type TFormValue = 'text' | 'text_extra' | 'due' | 'is_favorite' | 'state' | 'parent_id'

interface IForm {
    text: string
    text_extra?: string
    due: string | null
    is_favorite: boolean
    state: 0 | 10
    parent_id?: number | null
}

const AddTaskToDo: FC<AddTaskProps> = ({ showAddTaskModal, setShowAddTaskModal, type }) => {
    const { colorScheme } = useColorScheme()

    const [show, setShow] = useState(false)
    const [dateText, setDateText] = useState('')

    const { addTodo } = useAddTodo()

    const { addTodoFormType, selectedTodoId, validateTodo, noValidTodoText, noValidTodoDetail } = useStore(
        (state) => state
    )
    console.log()
    const [form, setForm] = useState<IForm>({
        text: '',
        text_extra: '',
        due: null,
        is_favorite: false,
        state: 0,
        parent_id: null
    })

    const onChange = (value: any, key: TFormValue) => {
        setForm({ ...form, [key]: value })
    }

    const onChangeDate = (date: DateType) => {
        // @ts-ignore
        const due = new Date(date && date.$d)
        const formattedDate =
            ('0' + (due.getMonth() + 1)).slice(-2) +
            '.' +
            ('0' + due.getDate()).slice(-2) +
            '.' +
            due.getFullYear()

        setDateText(formattedDate)
        setForm({ ...form, due: due.toISOString() })
        setShow(false)
    }
    const handleKeyPress = (event: any, form?: IForm) => {
        if (event.key === 'Escape') {
            clearForm()
            setShowAddTaskModal(false)
            setShow(false)
        }
        if (event.key === 'Enter' && form) {
            const regexCheck = /^(.*\S.*){1,}$/
            
            const isTextValid = regexCheck.test(form.text)
            const isTextExtraValid = regexCheck.test(form.text_extra as string)

            if (isTextValid || isTextExtraValid) {
                if (addTodoFormType === type) {
                    console.log(form)
                    addTodo({
                        ...form,
                        parent_id: addTodoFormType === 'subtask' ? selectedTodoId : null
                    })
                    clearForm()
                    setShowAddTaskModal(false)

                }
            } else {
                validateTodo(!isTextValid)
                validateTodo(!isTextExtraValid, true)
            }
            
        }
    }

    useEffect(() => {
        const regexCheck = /^(.*\S.*){1,}$/
        const isTextValid = regexCheck.test(form.text)
        const isTextExtraValid = regexCheck.test(form.text_extra as string)

        if (form.text && noValidTodoText) {
            validateTodo(isTextValid)
        }
        if (form.text_extra && noValidTodoDetail) {
            validateTodo(isTextExtraValid, true)
        }
        if (isTextValid || isTextExtraValid && noValidTodoText || noValidTodoDetail) {
            validateTodo(false)
            validateTodo(false, true)
        } 
        const handleKeyDown = (event: any) =>
            handleKeyPress(event, { ...form, due: !dateText.length ? null : form.due })
        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [form, dateText])

    const clearForm = () => {
        setForm({
            text: '',
            text_extra: '',
            due: null,
            is_favorite: false,
            state: 0,
            parent_id: null
        })
        setDateText('')
    }
    const clearDate = () => {
        setDateText('')
        setShow(false)
    }

    return (
        showAddTaskModal && (
            <View style={{ gap: 8 }} className='p-2 min-h-[140] w-full mr-4 rounded-b-lg bg-gray-100'>
                <View className='flex-1 flex-col'>
                    <View className='bg-sky-400 flex-row ml-[24]'>
                        <Button
                            icon={form.state === 10 ? 'CheckCircle' : 'Circle'}
                            className='p-base-x2'
                            sizeIcon={20}
                            colorIcon={
                                form.state === 10 ? (colorScheme === 'light' ? '#1F2937' : '#38BDF8') : ''
                            }
                            onPress={() => onChange(form.state === 0 ? 10 : 0, 'state')}
                        />
                        <TextInput
                            // @ts-ignore
                            style={{ outline: 'none' }}
                            placeholder='Title'
                            placeholderTextColor='#6B7280'
                            value={form.text}
                            textAlign='left'
                            autoFocus
                            onChangeText={(value) => onChange(value, 'text')}
                            onKeyPress={(e) => handleKeyPress(e)}
                            className={cn('h-[36] w-full bg-gray-100 border-none mr-2 p-2', {
                                'border border-error bg-error/20 text-error': noValidTodoText
                            })}
                        />
                        <Button
                            className='p-2'
                            icon='Star'
                            fillIcon={
                                form.is_favorite ? (colorScheme === 'light' ? '#1F2937' : '#38BDF8') : ''
                            }
                            sizeIcon={20}
                            onPress={() => onChange(!form.is_favorite, 'is_favorite')}
                        />
                    </View>
                    <View className='bg-sky-400 flex-row ml-[56]'>
                        <Button className='p-2 mr-2' icon='PencilLine' sizeIcon={20} />
                        <TextInput
                            // @ts-ignore
                            style={{ outline: 'none' }}
                            placeholder='Details'
                            placeholderTextColor='#6B7280'
                            value={form.text_extra}
                            onChangeText={(value) => onChange(value, 'text_extra')}
                            onKeyPress={(e) => handleKeyPress(e)}
                            multiline
                            textAlign='left'
                            autoFocus
                            className={cn('h-[36] w-full bg-gray-100 border-none p-2', {
                                'border border-error bg-error/20 text-error': noValidTodoDetail
                            })}
                        />
                    </View>
                    <View className='bg-sky-400 flex-row ml-[56px]'>
                        <Icon name='Calendar' size={20} className='mr-2, p-2' />
                        <TextInput
                            // @ts-ignore
                            style={{ outline: 'none' }}
                            className=' h-[36] w-full bg-gray-100 border-none p-2'
                            placeholder={'Day/Time'}
                            value={dateText}
                            textAlign='left'
                            placeholderTextColor='#6B7280'
                            onFocus={() => setShow(true)}
                        />
                        {!!dateText.length && (
                            <Button
                                icon={'X'}
                                className='h-9 w-9 p-2'
                                sizeIcon={20}
                                colorIcon={
                                    form.state === 10 ? (colorScheme === 'light' ? '#1F2937' : '#38BDF8') : ''
                                }
                                onPress={clearDate}
                            />

                        )}
                    </View>
                </View>
                {show && (
                    <View>
                        <View className='relative flex-1 left-[40] w-[200px] justify-center flex-row'>
                            <DateTimePicker
                                mode='single'
                                date={new Date()}
                                selectedItemColor={'#6B7280'}
                                onChange={(date) => onChangeDate(date.date)}
                                height={160}
                                headerContainerStyle={{
                                    justifyContent: 'center',
                                    padding: 2,
                                    height: 25,
                                    marginBottom: 0
                                }}
                                headerTextStyle={{
                                    fontSize: 12
                                }}
                                headerButtonSize={12}
                                calendarTextStyle={{
                                    fontSize: 12,
                                    padding: 2
                                }}
                                headerTextContainerStyle={{
                                    marginTop: 0,
                                    padding: 2
                                }}
                                weekDaysContainerStyle={{
                                    height: 25,
                                    margin: 0,
                                    justifyContent: 'center',
                                    padding: 0,
                                    marginBottom: 5
                                }}
                                dayContainerStyle={{
                                    height: 12,
                                    width: 20,
                                    margin: 2,
                                    padding: 2,
                                    justifyContent: 'center'
                                }}
                                yearContainerStyle={{
                                    width: 40,
                                    height: 20,
                                    justifyContent: 'center'
                                }}
                            />
                        </View>
                    </View>
                )}
            </View>
        )
    )
}

export default AddTaskToDo
