import cn from 'clsx'
import { StyleProp, View, ViewStyle } from 'react-native'
import { FC } from 'react'
import Typography from '../typography/Typography'
import { useColors } from '@/hooks/useColors'
import { replaceText, useTextLayoutHandlers } from '@/utils/replaceText'

interface IProps {
    text?: string
    type?: 'sender' | 'title' | 'text' | 'date'
    className?: string
    delimiter?: boolean
    vertical?: boolean
    read?: boolean
    position?: 'vertical' | 'horizontal'
    onTextOverflow?: (isOverflowed: boolean) => void
    style?: StyleProp<ViewStyle>
}

const RowText: FC<IProps> = ({
    className,
    type = 'text',
    vertical = true,
    text = '',
    delimiter = false,
    read = true,
    onTextOverflow,
    style
}) => {
    const { text: textColor } = useColors()
    const { handleContainerLayout, handleTextLayout } = useTextLayoutHandlers(onTextOverflow)

    const verticalStyle = {
        sender: vertical ? 'max-w-w[140px]' : 'max-w-[160px]',
        title: vertical ? 'max-w-[90px]' : 'max-w-[140px]',
        text: vertical ? 'w-fit' : 'w-full',
        date: undefined
    }

    const variantStyle = read
        ? type !== 'date'
            ? 'body'
            : 'label-date'
        : type === 'text' || delimiter
        ? 'body'
        : type !== 'date'
        ? 'body-bold'
        : 'label-date-bold'

    const content = {
        sender: vertical ? replaceText(text, 16) : replaceText(text, 19),
        title: vertical ? replaceText(text, 14) : replaceText(text, 13),
        text,
        date: text
    }

    return (
        <View
            style={style}
            className={cn('overflow-hidden', verticalStyle[type], className)}
            onLayout={handleContainerLayout}>
            <Typography
                numberOfLines={1}
                onLayout={handleTextLayout}
                ellipsizeMode='tail'
                variant={variantStyle}
                nowrap={!delimiter}
                className={!delimiter ? textColor.boldText : textColor.black}>
                {!delimiter ? content[type] : '-'}
            </Typography>
        </View>
    )
}

export default RowText