import { useColorScheme } from 'nativewind'
import { useMemo } from 'react'

export const useColors = () => {
	const { colorScheme } = useColorScheme()
	const isLight = useMemo(() => colorScheme === 'light', [colorScheme])

	const bg = useMemo(
		() => ({
			dashboardSurface: isLight ? 'bg-gray-700' : 'bg-gray-900',
			black: isLight ? 'bg-black' : 'bg-gray-500',
			white: isLight ? 'bg-white' : 'bg-gray-800',
			hoverSurface: isLight ? 'bg-gray-200' : 'bg-gray-900',
			scrolldownCounter: isLight ? 'bg-gray-200' : 'bg-gray-900',
			outcomingMessage: isLight ? 'bg-sky-200' : 'bg-sky-900',
			inactiveSurface: isLight ? 'bg-gray-100' : 'bg-black',
			infoSurface: isLight ? 'bg-gray-300' : 'bg-gray-700',
			tooltip: isLight ? 'bg-gray-800' : 'bg-sky',
			errorLightSurface: isLight ? 'bg-red-100' : 'bg-red-800',
			errorSelectedSurface: isLight ? 'bg-red-200' : 'bg-red-900',
			selectedMailInList: isLight ? 'bg-sky-100' : 'bg-sky-800',
			hoverOverHoverSurface: isLight ? 'bg-gray-300' : 'bg-gray-700',
			hoverIconSurface: isLight ? 'bg-gray-100' : 'bg-black',
			infoBackground: isLight ? 'bg-sky-50' : 'bg-sky-700',
			inactiveIcons: isLight ? 'bg-gray-400' : 'bg-gray-600'
		}),
		[isLight]
	)

	const color = useMemo(
		() => ({
			black: isLight ? 'black' : 'gray-500',
			white: isLight ? 'white' : 'gray-800',
			tooltip: isLight ? 'gray-800' : 'sky',
			star: isLight ? 'gray-800' : 'sky',
			disabledIcons: isLight ? 'gray-300' : 'gray-700',
			inactiveIcons: isLight ? 'gray-400' : 'gray-600',
			activeSky: isLight ? 'sky' : 'sky'
			
		}),
		[isLight]
	)

	const text = useMemo(
		() => ({
			boldText: isLight ? 'text-gray-900' : 'text-gray-400',
			black: isLight ? 'text-black' : 'text-gray-500',
			white: isLight ? 'text-white' : 'text-gray-800',
			inactiveText: isLight ? 'text-gray-400' : 'text-gray-600',
			disabledButton: isLight ? 'text-gray-300' : 'text-gray-700',
			disabledText: isLight ? 'text-gray-300' : 'text-gray-700'
		}),
		[isLight]
	)

	const border = useMemo(
		() => ({
			bold: isLight ? 'border-gray-900' : 'border-gray-400',
			divider: isLight ? 'border-gray-300' : 'border-gray-700',
			dividerLight: isLight ? 'border-gray-100' : 'border-black',
			black: isLight ? 'border-black' : 'border-gray-500'
		}),
		[isLight]
	)

	return { bg, text, border, color }
}
