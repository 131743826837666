import { FC, useState } from 'react'
import { View } from 'react-native'
import { Button, Dropdown, InputSearch } from '@/components/ui'
import { Pressable } from 'react-native-web-hover'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { useStore } from '@/shared/store'

interface IProps {
	selectedTodo: number
	countTodo: number
	selectTodo: () => void
}

const Header: FC<IProps> = ({ selectedTodo, countTodo, selectTodo }) => {
	const [dropdownVisible, setDropdownVisible] = useState(false)
	const [valueType, setValueType] = useState('')
	const { color, bg } = useColors()

    const { searchTodosTab, searchValueTab, changeTodos } = useStore(state => state)

	const toggleDropdown = () => {
		setDropdownVisible(!dropdownVisible)
	}
	const closeDropdown = () => {
		setDropdownVisible(false)
	}

	return (
        <View className='flex-row justify-between items-center relative z-20'>
            <View className='flex-row justify-between items-center  ' >
                <View style={{ gap: 8 }}>
                    <View>
                        <Pressable>
                            {({ hovered }) => (
                                <View
                                    className={cn(
                                        'flex-row items-center rounded-base-x2 mr-[32px]',
                                        (hovered || valueType || selectedTodo > 0) && bg.hoverIconSurface
                                    )}>
                                    <Button
                                        icon={
                                            selectedTodo === countTodo
                                                ? 'CheckSquare'
                                                : selectedTodo < countTodo && selectedTodo
                                                ? 'MinusSquare'
                                                : !selectedTodo
                                                ? 'Square'
                                                : ''
                                        }
                                        colorIcon={color.black}
                                        sizeIcon={20}
                                        className='p-base-x2'
                                        onPress={selectTodo}
                                    />
                                    <Dropdown
                                        left={-35}
                                        setValue={setValueType}
                                        variant='body'
                                        classNameText='text-black'
                                        isVisible={dropdownVisible}
                                        onClose={closeDropdown}
                                        options={[
                                            { text: 'All' },
                                            { text: 'None' },
                                            { text: 'Expired' },
                                            { text: 'Important' },
                                            { text: 'Not important' }
                                        ]}
                                        button={
                                            <Button
                                                onPress={toggleDropdown}
                                                colorIcon={color.black}
                                                icon='ChevronDown'
                                                sizeIcon={20}
                                                className='p-base-x2'
                                            />
                                        }
                                    />
                                </View>
                            )}
                        </Pressable>
                    </View>
                </View>
                <View className='flex-row items-center' style={{ gap: 8 }}>
                    <Button
                        icon='Sun'
                        sizeIcon={20}
                        colorIcon={color.black}
                        className='p-base-x2 rounded-base-x2'
                        styleOnHover={bg.hoverIconSurface}
                        onPress={() => changeTodos('today')}
                    />
                    <Button
                        icon='Haze'
                        sizeIcon={20}
                        colorIcon={color.black}
                        className='p-base-x2 rounded-base-x2'
                        styleOnHover={bg.hoverIconSurface}
                        onPress={() => changeTodos('tomorrow')}
                    />
                    <Button
                        icon='Calendar'
                        sizeIcon={20}
                        colorIcon={color.black}
                        className='p-base-x2 rounded-base-x2'
                        styleOnHover={bg.hoverIconSurface}
                        onPress={() => changeTodos('calendar')}
                    />
                    <Button
                        icon='Star'
                        sizeIcon={20}
                        colorIcon={color.black}
                        className='p-base-x2 rounded-base-x2'
                        styleOnHover={bg.hoverIconSurface}
                        onPress={() => changeTodos('important')}
                    />
                    <Button
                        icon='StarOff'
                        sizeIcon={20}
                        colorIcon={color.black}
                        className='p-base-x2 rounded-base-x2'
                        styleOnHover={bg.hoverIconSurface}
                        onPress={() => changeTodos('no-important')}
                    />
                    <Button
                        icon='Trash2'
                        sizeIcon={20}
                        colorIcon={color.black}
                        className='p-base-x2 rounded-base-x2'
                        styleOnHover={bg.hoverIconSurface}
                        onPress={() => changeTodos('remove')}
                    />
                </View>
                <InputSearch
                    onChange={(value) => searchTodosTab(value)}
                    value={searchValueTab}
                    placeholder='Search mail'
                    className='w-[264px] desktop:w-[440px] ml-[24px] '
                />
            </View>
        </View>
    )
}

export default Header
