import cn from 'clsx'
import { FC, useState } from 'react'
import { Image, Pressable, View } from 'react-native'
import { Button, Icon, RowActions, RowText, Typography } from '@/components/ui'
import { Pressable as PressableHover } from 'react-native-web-hover'
import { ScivePowerContainer } from '../index'
import { useTypedNavigation } from '@/hooks/useTypedNavigation'
import { useTypedRoute } from '@/hooks/useTypedRoute'
import { useMailPosition } from '@/context/MailPositionContext'
import { TPath } from '@/components/screens/inbox/inbox.interface'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'
import { TMessageId } from '@/components/ui/summary/types'
import { DataTable } from 'react-native-paper'
import { IThread } from '@/shared/types/dashboard.interface'
import { useTextLayoutHandlers } from '@/utils/replaceText'

interface IProps {
    read: boolean
    id: TMessageId
    thread: IThread
}

const TableRow: FC<IProps> = ({ read, id, thread }) => {
    const { colorScheme } = useColorScheme()
    const { position } = useMailPosition()
    const { bg, border, color, text: textColor } = useColors()
    const [isImportant, setIsImportant] = useState(false)
    const { params } = useTypedRoute<'Inbox'>()
    const isActive = params?.id === id
    const { navigate } = useTypedNavigation()
    const [isSelected, setIsSelected] = useState(false)
    const [visibleScivePowerContainer, setVisibleScivePowerContainer] = useState(false)

    const sender = 'Marla Singer Marla Singer'
    const title = 'Find and pay Find and pay'

    const [isTextOverflowed, setIsTextOverflowed] = useState(false)
    const { handleContainerLayout, handleTextLayout } = useTextLayoutHandlers(setIsTextOverflowed)

    const fileNames = Array(3).fill('file_name_example')

    return (
        <PressableHover
            // @ts-ignore
            style={{ transition: 'all 0.3s ease-out' }}
            onPress={() =>
                navigate('Inbox', {
                    path: params?.path as TPath,
                    id: id
                })
            }>
            {({ hovered }) => (
                <View className='flex-col'>
                    <DataTable
                        className={cn(
                            hovered && bg.hoverIconSurface,
                            (visibleScivePowerContainer || isActive) && bg.selectedMailInList,
                            (visibleScivePowerContainer || isActive) && hovered && bg.outcomingMessage
                        )}>
                        <DataTable.Row
                            className={cn(
                                'relative pt-base-x1 pb-base-x1 flex-row justify-between overflow-hidden'
                            )}
                            style={{ borderWidth: 0 }}>
                            <DataTable.Cell style={{ maxWidth: 36, marginRight: 8 }}>
                                <Button
                                    icon={isSelected ? 'CheckSquare' : 'Square'}
                                    sizeIcon={20}
                                    colorIcon={color.black}
                                    onPress={() => setIsSelected(!isSelected)}
                                />
                            </DataTable.Cell>
                            <DataTable.Cell style={{ maxWidth: 36, gap: 8 }}>
                                <Pressable onPress={() => setIsImportant(!isImportant)}>
                                    <Image
                                        source={require('@/assets/icons/important.svg')}
                                        tintColor={
                                            isImportant ? '#FDBA74' : colorScheme === 'dark' ? '#374151' : ''
                                        }
                                        style={{ width: 15, height: 14 }}
                                    />
                                </Pressable>
                            </DataTable.Cell>
                            <DataTable.Cell
                                style={{ maxWidth: position === 'vertical' ? 140 : 160, marginRight: 8 }}>
                                <RowText
                                    type='sender'
                                    text={sender}
                                    read={read}
                                    vertical={position === 'vertical'}
                                />
                            </DataTable.Cell>
                            <DataTable.Cell
                                style={{
                                    padding: 0,
                                    maxWidth: position === 'vertical' ? 90 : 140,
                                    marginRight: 8
                                }}>
                                <RowText
                                    text={title}
                                    read={read}
                                    type='title'
                                    vertical={position === 'vertical'}
                                />
                            </DataTable.Cell>
                            <DataTable.Cell onLayout={handleContainerLayout} style={{ overflow: 'hidden' }}>
                                <Typography
                                    numberOfLines={1}
                                    onLayout={handleTextLayout}
                                    // @ts-ignore
                                    style={{
                                        overflow: 'hidden',
                                        width: 'fit-content'
                                    }}
                                    ellipsizeMode='tail'
                                    variant={'body'}
                                    nowrap={true}
                                    className={textColor.black}>
                                    {thread?.snippet || ''}
                                </Typography>
                            </DataTable.Cell>
                            <DataTable.Cell style={{ maxWidth: 24 }}>
                                <View></View>
                            </DataTable.Cell>

                            <DataTable.Cell style={{ maxWidth: hovered ? 120 : 50 }}>
                                {hovered ? (
                                    <RowActions
                                        visibleScive={setVisibleScivePowerContainer}
                                        isVisible={visibleScivePowerContainer}
                                        isSelected={setIsSelected}
                                    />
                                ) : (
                                    <RowText
                                        className='pt-base-x1 pb-base-x1 '
                                        type='date'
                                        text={'7:20 AM'}
                                        read={read}
                                        vertical={position === 'vertical'}
                                    />
                                )}
                            </DataTable.Cell>
                        </DataTable.Row>
                        <DataTable.Row>
                            <DataTable.Cell style={{ maxWidth: 36, marginRight: 8 }}>
                                <View></View>
                            </DataTable.Cell>
                            {fileNames.length &&
                                fileNames.slice(0, 2).map((name, idx) => (
                                    <DataTable.Cell key={idx} className='max-w-[177px]'>
                                        <View
                                            className='flex-row border rounded-full px-3 py-1 border-devider'
                                            style={{ gap: 8 }}>
                                            <View className='w-6 h-6 flex justify-center items-center rounded bg-devider'>
                                                <Icon name='File' size={16} strokeWidth={2} fill='' />
                                            </View>
                                            <Typography
                                                numberOfLines={1}
                                                onLayout={handleTextLayout}
                                                variant='body'
                                                className='max-w-[121px] text-base-body2'
                                                // @ts-ignore
                                                style={{
                                                    overflow: 'hidden',
                                                    width: 'fit-content'
                                                }}
                                                ellipsizeMode='tail'
                                                nowrap={true}>
                                                {name}
                                            </Typography>
                                        </View>
                                    </DataTable.Cell>
                                ))}
                            {fileNames.length > 2 && (
                                <DataTable.Cell className='max-w-[177px]'>
                                    <View
                                        className='flex-row border rounded-full px-3 py-1 border-devider'
                                        style={{ gap: 8 }}>
                                        <Typography variant='body' className='max-w-[121px] text-base-body2'>
                                            +{fileNames.length - 2}
                                        </Typography>
                                    </View>
                                </DataTable.Cell>
                            )}
                        </DataTable.Row>
                    </DataTable>
                    {visibleScivePowerContainer && <ScivePowerContainer id={id} />}
                </View>
            )}
        </PressableHover>
    )
}

export default TableRow
