import { FC } from 'react'
import { View } from 'react-native'
import { IMessages } from './messages.interface'
import MessageBubble from '@/components/ui/messages/MessageBubble'
import cn from 'clsx'
import { useSizeSelector } from '@/context/SizeSelectorContext'
import { Typography } from '@/components/ui'
import { useColors } from '@/hooks/useColors'

const Messages: FC<IMessages> = ({}) => {
	const { size } = useSizeSelector()
	const { text } = useColors()
	const chat = {
		messages: [
			{
				text: 'Example text Example text',
				sender: 'Auth',
				date: '14:12'
			},
			{
				text: 'Example text Example text',
				sender: 'I',
				date: '14:12'
			},
			{
				text: 'Example text Example text The promo code is valid until August 31, 2023. Example text Example text The promo code is valid until August 31, 2023Example text Example text The promo code is valid until August 31, 2023Example text Example text The promo code is valid until August 31, 2023Example text Example text The promo code is valid until August 31, 2023',
				sender: 'Auth',
				date: '14:12'
			},
			{
				text: 'Example text Example text',
				sender: 'Auth',
				date: '14:12'
			},
			{
				text: 'Example text Example text Example text Example text The promo code is valid until August 31, 2023 м Example text Example text The promo code is valid until August 31, 2023 Example text Example text The promo code is valid until August 31, 2023',
				sender: 'I',
				date: '14:12'
			},
			{
				text: 'Example text Example text The promo code is valid until August 31, 2023. ',
				sender: 'I',
				date: '14:12'
			},
			{
				text: 'Example text Example text',
				sender: 'Auth',
				date: '14:12'
			},
			{
				text: 'Example text Example text',
				sender: 'I',
				date: '14:12'
			},
			{
				text: 'Example text Example text The promo code is valid until August 31, 2023. ',
				sender: 'Auth',
				date: '14:12'
			}
		]
	}

	return (
		<View
			className={cn(
				'flex-col mr-base-x2 max-w-[600px]',
				size === 'small' && 'max-w-full',
				size === 'large' && 'm-auto'
			)}
			style={{ gap: 8 }}
		>
			{chat.messages.map((message, index) => {
				const isSameSenderAsPrevious =
					index > 0 && message.sender === chat.messages[index - 1].sender
				const isSameSenderAsNext =
					index < chat.messages.length - 1 &&
					message.sender === chat.messages[index + 1].sender

				return (
					<View key={index}>
						<Typography
							variant='h4'
							className={cn(
								'font-bold text-black-700 text-center mt-base-x2 mb-base-x4',
								text.boldText
							)}
						>
							{index + 1} September
						</Typography>

						<MessageBubble
							{...message}
							showAvatar={!isSameSenderAsPrevious}
							showDate={!isSameSenderAsNext}
						/>
					</View>
				)
			})}
		</View>
	)
}

export default Messages
