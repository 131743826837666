import { StateCreator } from 'zustand'
import { IThreadsSlice } from '../types'
import { htmlToText } from '@/utils/htmlToText'

export const threadsSlice: StateCreator<IThreadsSlice> = (set, get) => ({
    threads: [],
    thread: {},
    sendMessageForm: {
        to: '',
        subject: '',
        prompt: '',
        length: 0,
        style: 0,
        text: ''
    },
    noValidEmail: false,
    noValidPrompt: false,
    showCompose: false,
    currentThreadChat: {
        title: 'Find and pay for a ticket through ...',
        sender: 'Scive Daily Digest lon Scive Daily Digest lon Scive Daily Digest lon Scive Daily Digest lon Daily Digest lon Scive Daily Digest lon',
        senderAddress: 'maridim.dev@gmail.com',
        date: '7:20 AM (3 hours ago)'
    },

    setShowCompose: (showCompose = false) => set(() => ({ showCompose })),
    setThreads: (threads) => set(() => ({ threads: threads.reverse() })),

    validateField: (fieldValue, fieldName) => {
        if (fieldName === 'email') {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

            if (!emailRegex.test(fieldValue)) {
                return set(() => ({ noValidEmail: true }))
            }
        }

        if (fieldName === 'prompt' && !fieldValue.length) {
            return set(() => ({ noValidPrompt: true }))
        }

        return set(() => ({
            noValidEmail: false,
            noValidPrompt: false
        }))
    },
    clearValidateField: (fieldName) => {
        setTimeout(() => {
            if (!fieldName) {
                return set(() => ({
                    noValidEmail: false,
                    noValidPrompt: false
                }))
            }
            if (fieldName === 'email') {
                return set(() => ({ noValidEmail: false }))
            }

            if (fieldName === 'prompt') {
                return set(() => ({ noValidPrompt: false }))
            }
        }, 3000)
    },

    setSendMessageForm: (name, value) => {
        // console.log('name,value', htmlToText(value as string), name)

        return set(() => ({
            noValidEmail: false,
            sendMessageForm: {
                ...get().sendMessageForm,
                [name]: value
            }
        }))
    },
    clearSendMessageForm: () =>
        set(() => ({
            sendMessageForm: {
                ...get().sendMessageForm,
                to: '',
                subject: '',
                prompt: '',
                text: ''
            }
        }))
})
