import { FC, useLayoutEffect, useRef, useState } from 'react'
import {
	Button,
	ButtonPlain,
	Compose,
	Messages,
	Typography
} from '@/components/ui'
import {
	NativeScrollEvent,
	NativeSyntheticEvent,
	ScrollView,
	View
} from 'react-native'
import { TPath } from '../../../inbox.interface'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'

interface IProps {
	path: TPath
}
const Content: FC<IProps> = ({ path }) => {
	const scrollViewRef = useRef<ScrollView | null>(null)
	const [count, setCount] = useState(6)
	const [visibleScrollDown, setVisibleScrollDown] = useState(false)
	const { border, text, bg, color } = useColors()
	
	const scrollDown = () => {
		if (scrollViewRef.current) {
			scrollViewRef.current.scrollToEnd({ animated: false })
			setCount(0)
		}
	}

	const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
		const currentScrollY = event.nativeEvent.contentOffset.y
		const scrollViewHeight = event.nativeEvent.layoutMeasurement.height
		const contentHeight = event.nativeEvent.contentSize.height
		const isAtBottom = currentScrollY >= contentHeight - scrollViewHeight

		if (isAtBottom) {
			setVisibleScrollDown(false)
		} else {
			setVisibleScrollDown(true)
		}
	}

	useLayoutEffect(() => {
		if (scrollViewRef.current) {
			// Прокрутка ScrollView в самый низ
			scrollViewRef.current.scrollToEnd({ animated: false })
		}
	}, [])

	return (
		<>
			<ScrollView
				onScroll={handleScroll}
				scrollEventThrottle={60}
				className={cn(
					'relative flex-1 mr-base-x2 mt-base-x2 pb-base-x2 border-b',
					border.divider
				)}
				ref={scrollViewRef}
			>
				{path === 'spam' && (
					<View
						className={cn(
							'rounded-base-x2 bg-gray-300 p-base-x4 flex-row justify-between mr-base-x2 ',
							bg.infoSurface
						)}
					>
						<View className='flex-col' style={{ gap: 8 }}>
							<Typography variant='calout' className={text.boldText}>
								Why is this message in spam? You previously marked messages from
								no-reply@gosuslugi.ru as spam.
							</Typography>
							<ButtonPlain>Report not spam</ButtonPlain>
						</View>
						<Button
							icon='Info'
							sizeIcon={20}
							className='h-base-x5 '
							colorIcon={color.black}
						/>
					</View>
				)}
				<Messages />
			</ScrollView>
			{visibleScrollDown && (
				<Button
					className={cn(
						'absolute bottom-base-x6 right-[25px] z-20 w-base-x10 h-base-x10 rounded-base-x16 flex-col items-center justify-center shadow-sm',
						bg.scrolldownCounter
					)}
					onPress={scrollDown}
					icon='ChevronDown'
					colorIcon={color.black}
					noMargin
				>
					{count ? (
						<Typography
							variant='label-date'
							className={cn(
								'absolute bottom-[30px] left-[-3px] bg-red text-center pl-base-x2 pr-base-x2 pb-base-x1 pt-base-x1 rounded-[100px] text-white w-[45px]',
								text.white
							)}
						>
							{count}
						</Typography>
					) : null}
				</Button>
			)}
			<Compose className='max-h-[300px] rounded-base-x0 pl-0 ml-base-x3' />
		</>
	)
}
export default Content
