import cn from 'clsx'
import { FC } from 'react'
import { View } from 'react-native'
import Header from './components/Header'
import Content from '@/components/screens/dashboard/components/mailChat/components/Content'
import { useColorScheme } from 'nativewind'
import { useStore } from '@/shared/store'

const MailChat: FC = () => {
    const { colorScheme } = useColorScheme()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'
    
    const { title, sender, senderAddress, date } = useStore((state) => state.currentThreadChat)

    return (
        <View className={cn('pt-base-x3 pb-base-x2 flex-1 justify-between border-b-[1px] ', deviderColor)}>
            <Header
                title={title}
                sender={sender}
                senderAddress={senderAddress}
                date={date}
            />
            <Content />
        </View>
    )
}

export default MailChat
