import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { ThreadsService } from '@/services/inbox/threads.api'
import { ISendGenerateMessage } from '@/shared/types/dashboard.interface'
import { EMessageGenerateLength, EMessageGenerateStyle } from '@/shared/store/enum'
import { IMessageForm } from '@/shared/store/types'

export const useSendGenerateMessage = () => {
    const [message, setMessage] = useState<ISendGenerateMessage>({
        prompt: '',
        length: EMessageGenerateLength[0],
        style: EMessageGenerateStyle[0],
        msgs_ids: []
    })
    // const { clearSendMessageForm, showCompose, currentThreadChat } = useStore((state) => state)

    const mutationResult = useMutation(['send_generate_message'], () => ThreadsService.sendGenerateMessage(message), {
        onSuccess(res) {
            // if (res.id) {
            //     clearSendMessageForm()
            // }
            console.log('Generate Message successfully', res)
        }
    })

    const sendGenerateMessage = async (data: IMessageForm) => {
        console.log(EMessageGenerateLength[data.length as number])
        console.log(EMessageGenerateStyle[data.style as number])

        if (!!data.prompt) {
            const newMessage = {
                prompt: data.prompt,
                length: EMessageGenerateLength[data.length as number],
                style: EMessageGenerateStyle[data.style as number],
                msgs_ids: []
            }
            console.log(newMessage)
            await setMessage({ ...newMessage })
            // await mutationResult.mutate()
        }
    }

    return {
        sendGenerateMessage,
        ...mutationResult
    }
}
