import { FC, useEffect, useState } from 'react'
import {
	AddTodoList,
	Compose,
	DetailedSummary,
	ExecutiveSummary,
	Highlights,
	Layout
} from '@/components/ui'
import { StyleSheet, View } from 'react-native'
import { MailChat, ModalContainer, Todo } from './components'
import { BlurView } from 'expo-blur'
import cn from 'clsx'
import {
	getAgreeTerms,
	getCourseCompleted,
	saveCourseCompleted
} from '@/services/app.helper'
import { useColorScheme } from 'nativewind'
import { useGetSummaryExtra } from '@/components/ui/summary/api'
import { IUpdateTodo } from '@/shared/types/todo.interface'
import { useAddTodo } from '../todo/api'

const Dashboard: FC = () => {
    const [stepModal, setStepModal] = useState<number>(0)
    const [courseCompleted, setCourseCompleted] = useState<boolean | null>(null)
    const [agree, setAgree] = useState(false)
    const [isLoading, setIsLoading] = useState<boolean | null>(null)

    const { colorScheme } = useColorScheme()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

    const { messageId, fetchSummaryExtra, isFetching, removeSummaryTodo } = useGetSummaryExtra()
    const { addTodo } = useAddTodo()

    useEffect(() => {
        setIsLoading(isFetching || null)
    }, [isFetching])

    useEffect(() => {
        const fetchData = async () => {
            const completed = await getCourseCompleted()
            setCourseCompleted(completed)
        }

        fetchData()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            await saveCourseCompleted(true)
            setCourseCompleted(true)
        }

        if (stepModal === 5 && agree) {
            fetchData()
        }
    }, [agree])

    useEffect(() => {
        const fetchDataAgree = async () => {
            const isAgree = await getAgreeTerms()
            setAgree(isAgree)
        }

        fetchDataAgree()
        const fetchData = async () => {
            await saveCourseCompleted(true)
            setCourseCompleted(true)
        }

        if (stepModal === 5 && agree) {
            fetchData()
        }
    }, [stepModal])


	const addTask = (todo: IUpdateTodo) => {
		removeSummaryTodo({ ...todo })
        addTodo({ ...todo });
    }

	return (
        <>
            <Layout className='flex-row flex-1 justify-between grid grid-cols-3' style={styles.row}>
                <View
                    className={cn(
                        'border-r-[1px] flex-1 min-w-[320px] desktop:max-w-[480px]',
                        stepModal === 1 && 'z-[1000] fixed',
                        deviderColor
                    )}
                    style={styles.column}>
                    <ExecutiveSummary selectMessage={fetchSummaryExtra} />
                    <DetailedSummary
                        messageId={messageId}
                        isLoading={isLoading}
                    />
                    <AddTodoList
                        className='h-[188px]'
                        isLoading={isLoading}
                        addTask={addTask}
                    />
                </View>
                <View
                    className={cn(
                        'border-x-[1px] flex-1 min-w-[536px] desktop:min-w-[656px]',
                        stepModal === 2 && 'z-[1000] fixed',
                        deviderColor
                    )}
                    style={styles.column}>
                    <MailChat />
                    <Compose className='max-h-[300px]' />
                </View>

                <View
                    className={cn(
                        'border-l-[1px] flex-1 min-w-[320px] desktop:max-w-[480px]',
                        stepModal === 3 && 'z-[1000] fixed',
                        deviderColor
                    )}
                    style={styles.column}>
                    <Todo />
                    <Highlights isLoading={isLoading} />
                </View>
                {courseCompleted === false && (
                    <>
                        <BlurView
                            intensity={40}
                            tint='dark'
                            className='absolute top-0 left-0 right-0 bottom-0 m-[-8px] mb-[-24px]'
                        />
                        <ModalContainer setStep={setStepModal} step={stepModal} />
                    </>
                )}
            </Layout>
        </>
    )
}

const styles = StyleSheet.create({
	column: {
		gap: 8
	},
	row: {
		gap: 4
		
	},
})

export default Dashboard
