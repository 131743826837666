import { FC, ReactNode, useState } from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';
import { Button, Loader, Typography } from '@/components/ui';
import { StyledProps } from 'nativewind';
import cn from 'clsx';
import { useColors } from '@/hooks/useColors';
import { TSummaryState } from '../types';
import { useTypedNavigation } from '@/hooks/useTypedNavigation';
import { TMessageId } from '../summary/types';
import { IBody } from '@/shared/types/dashboard.interface';

interface IProps {
    body?: IBody[] | string;
    placeholder?: string;
    children?: ReactNode;
    className?: StyledProps<string>;
    style?: StyleProp<ViewStyle>;
    status?: TSummaryState;
    selectMessage?: (msgId: TMessageId) => void
    marginTop?: boolean | string
}

const Body: FC<IProps> = ({
    body,
    placeholder,
    children,
    className,
    style,
    status,
    selectMessage,
    marginTop = true
}) => {
    const [isHoveredId, setIsHoveredId] = useState<TMessageId>(null)
    const { navigate } = useTypedNavigation();
    const { text: textColor } = useColors();
    const limitedText = 'Oops, the number of detailed report generation is over. Need more?';

    return (
        <View
            className={cn(
                'flex-1 pl-base-x6 pr-base-x2 pb-base-x4 mr-base-x2',
                (children || body?.length) && className,
                marginTop ? 'mt-base-x4' : typeof marginTop === 'string' ? marginTop : ''
            )}
            //@ts-ignore
            style={[{ overflowY: 'auto' }, style]}>
            {children}
            {status === 'default' && (
                <View
                    //@ts-ignore
                    style={{ userSelect: 'none' }}>
                    <Typography variant='body' className={textColor.inactiveText}>
                        {placeholder}
                    </Typography>
                </View>
            )}
            {status === 'generated' && (
                <Typography variant='body' className={textColor.boldText}>
                    {body?.length && typeof body !== 'string'
                        ? body.map((item, idx) => (
                              <Text
                                  key={idx}
                                  //@ts-ignore
                                  onMouseLeave={() => setIsHoveredId(null)}
                                  onMouseEnter={() => setIsHoveredId(idx)}
                                  onPress={() => selectMessage && selectMessage(idx)}
                                  style={isHoveredId === idx && { textDecorationLine: 'underline' }}>
                                  {' '}
                                  {item.sentence}
                              </Text>
                          ))
                        : typeof body === 'string' && body}
                </Typography>
            )}
            {status === 'limited' && (
                <Typography
                    variant='body'
                    className={cn('text-base-body', textColor.boldText, textColor.inactiveText)}>
                    {limitedText}
                    <Button
                        variant='body'
                        className={cn(
                            'pl-2 underline underline-offset-4',
                            textColor.boldText,
                            textColor.black
                        )}
                        onPress={() =>
                            navigate('Settings', {
                                path: 'plan'
                            })
                        }>
                        Check out our plans.
                    </Button>
                </Typography>
            )}
        </View>
    )
};

export default Body;

