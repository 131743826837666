import { IUser } from "./user.interface"

export enum EnumStorage {
	TOKEN = 'token',
	USER = 'user'
}

export interface IToken {
	token: string
}

export interface IAuthResponse {
	user: IUser
}

export interface ILoginResponse {
	url: string
}
