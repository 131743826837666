import AsyncStorage from '@react-native-async-storage/async-storage'
import {
	EnumStorage,
	IAuthResponse,
	ILoginResponse
} from '@/shared/types/auth.interface'
import { request } from '@/services/api/request.api'
import {
	deleteTokenStorage,
	saveTokenStorage,
	saveToStorage
} from '@/services/auth/auth.helper'
import { getAuthUrl } from '@/config/api.config'

export const AuthService = {
	async login() {
		return request<ILoginResponse>({
			url: getAuthUrl('/google/login'),
			method: 'POST'
		})
	},

	async getUser(token: string) {
		await saveTokenStorage({ token })

		const responseUser = await request<IAuthResponse>({
			url: getAuthUrl('/user/'),
			method: 'GET'
		})

		if (responseUser) {
			await saveToStorage(responseUser)
			window.history.go(0)
		}
	},

	async logout() {
		const response = await request({
			url: getAuthUrl('/logout/'),
			method: 'POST'
		})
		await deleteTokenStorage()
		await AsyncStorage.removeItem(EnumStorage.USER)
	}
}
