import cn from 'clsx'
import { FC } from 'react'
import { View } from 'react-native'
import { ISummaryExtra } from '@/shared/types/dashboard.interface'
import { StyledProps } from 'nativewind'
import TodoItem from './TodoItem'
import { IUpdateTodo } from '@/shared/types/todo.interface'
import { getDate } from '@/shared/libs/hooks'

interface ITodoList {
    todos: ISummaryExtra[]
    className?: StyledProps<string>
    addTask: (todo: IUpdateTodo) => void
}

const TodoList: FC<ITodoList> = ({ todos, className, addTask }) => {
	return (
		<View className={cn('flex-col', className)}>
			{todos.map((todo, index) => (
				<TodoItem key={index} todo={todo} addTask={() => addTask({
					text: todo.text,
					due: getDate(),
				})} />
			))}
		</View>
	)
}

export default TodoList
