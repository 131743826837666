import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import { ScrollView, View } from 'react-native'
import ButtonDouble from '../ButtonDouble'
import './editor.css'
import { Button, Icon, Typography } from '@/components/ui'
import EmojiSelector, { Categories } from 'react-native-emoji-selector'
import cn from 'clsx'
import { htmlToText } from '@/utils/htmlToText'
import ModalTemplate from '../ModalTemplate'
import * as DocumentPicker from 'expo-document-picker'
import Toast from 'react-native-toast-message'
import { useColors } from '@/hooks/useColors'
import { IMessageFormName } from '@/shared/store/types'
import { useSendGenerateMessage } from '@/components/screens/inbox/api'

interface TToolbar {
    value: string
    quillRef: MutableRefObject<any>
    loadFile: (file: DocumentPicker.DocumentPickerAsset) => void
}

const Toolbar = ({ quillRef, value, loadFile }: TToolbar) => {
    const { bg } = useColors()
    const [visibleEmoji, setVisibleEmoji] = useState(false)
    const [visibleModalTemplate, setVisibleModalTemplate] = useState(false)
    const [visibleFormatingOptions, setVisibleFormatingOptions] = useState(false)
    const [prevValue, setPrevValue] = useState(htmlToText(value))
    const icons = ReactQuill.Quill.import('ui/icons')

    useEffect(() => {
        setPrevValue(htmlToText(value))
        if (htmlToText(value) !== prevValue) {
            setVisibleFormatingOptions(false)
        }
        setVisibleModalTemplate(false)
    }, [value])

    useEffect(() => {
        if (visibleFormatingOptions) {
            setVisibleModalTemplate(false)
            setVisibleEmoji(false)
        }
    }, [visibleFormatingOptions])

    useEffect(() => {
        if (visibleEmoji) {
            setVisibleFormatingOptions(false)
            setVisibleModalTemplate(false)
        }
    }, [visibleEmoji])

    useEffect(() => {
        if (visibleModalTemplate) {
            setVisibleFormatingOptions(false)
            setVisibleEmoji(false)
        }
    }, [visibleModalTemplate])

    const pickFile = async () => {
        const result = await DocumentPicker.getDocumentAsync({
            type: '*/*'
        })

        if (result.assets) {
            console.log(result.assets[0]?.size)
            if (result.assets && result.assets[0] && result.assets[0].size) {
                if (result.assets[0].size / 1024 > 2097152) {
                    Toast.show({
                        type: 'error',
                        text1: 'Add a file up to 2GB'
                    })
                }
            }
            loadFile(result.assets[0])
            /*const file = new FormData()
 			file.append('file', result.assets)*/
        }
    }

    icons[
        'bold'
    ] = `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none"><path d="M10 10C11.0609 10 12.0783 9.57857 12.8284 8.82843C13.5786 8.07828 14 7.06087 14 6C14 4.93913 13.5786 3.92172 12.8284 3.17157C12.0783 2.42143 11.0609 2 10 2H2V10M2 10H11C12.0609 10 13.0783 10.4214 13.8284 11.1716C14.5786 11.9217 15 12.9391 15 14C15 15.0609 14.5786 16.0783 13.8284 16.8284C13.0783 17.5786 12.0609 18 11 18H2V10Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>`
    icons[
        'italic'
    ] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M19 4H10M14 20H5M15 4L9 20" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`
    icons[
        'underline'
    ] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M6 4V10C6 11.5913 6.63214 13.1174 7.75736 14.2426C8.88258 15.3679 10.4087 16 12 16C13.5913 16 15.1174 15.3679 16.2426 14.2426C17.3679 13.1174 18 11.5913 18 10V4M4 20H20" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>`

    return (
        <View id='toolbar' className=' flex-row justify-start' style={{ gap: 8 }}>
            <Button className='border-0' onPress={() => setVisibleFormatingOptions(!visibleFormatingOptions)}>
                <Icon name='SpellCheck2' size={24} />
            </Button>
            <View
                className={cn(
                    'absolute bottom-[40px] flex-row left-0 rounded-base-x2 z-50 p-base-x1 shadow',
                    bg.white,
                    !visibleFormatingOptions && 'hidden'
                )}
                style={{ gap: 8 }}>
                <select className='ql-size' defaultValue='normal'>
                    <option value='small'>Small</option>
                    <option value='normal'>Normal</option>
                    <option value='large'>Large</option>
                    <option value='huge'>Huge</option>
                </select>
                <Button className='ql-bold'></Button>
                <Button className='ql-italic'></Button>
                <Button className='ql-underline'></Button>
            </View>
            <Button onPress={() => setVisibleEmoji(!visibleEmoji)}>
                <Icon name='SmilePlus' size={24} />
            </Button>
            <Button onPress={pickFile}>
                <Icon name='Paperclip' size={24} />
            </Button>
            <Button onPress={() => setVisibleModalTemplate(!visibleModalTemplate)}>
                <Icon name='FileText' size={24} />
            </Button>
            <Button>
                <Icon name='GraduationCap' size={24} />
            </Button>
            <ModalTemplate visible={visibleModalTemplate} />
            <View
                className={cn(
                    'absolute bottom-[40px] left-[40px] w-[232px] h-[280px] bg-white overflow-hidden rounded-base-x2 p-base-x2 pt-base-x3 z-50 shadow-md',
                    !visibleEmoji && 'hidden'
                )}>
                <EmojiSelector
                    category={Categories.symbols}
                    onEmojiSelected={(emoji) => {
                        if (quillRef.current) {
                            const cursorPosition = quillRef.current.getEditor().getLength()
                            quillRef.current.getEditor().insertText(cursorPosition, emoji)
                            quillRef.current.getEditor().setSelection(cursorPosition + 1)
                        }
                    }}
                />
            </View>
        </View>
    )
}

interface TProps {
    value: string
    setValue: (name: IMessageFormName, value: string | number) => void
    onSubmit: () => void
}

const Editor: FC<TProps> = ({ onSubmit, value, setValue }) => {
    const quillRef = useRef<ReactQuill | null>(null)
    const [files, setFiles] = useState<DocumentPicker.DocumentPickerAsset[]>([])
    const { isLoading } = useSendGenerateMessage()

    const modules = {
        toolbar: '#toolbar'
    }

    const formats = ['underline', 'size', 'bold', 'italic']

    useEffect(() => {
        scrollToolbarIntoView()
    }, [value])

    const scrollToolbarIntoView = () => {
        if (quillRef.current) {
            const quillEditor = quillRef.current?.getEditor()
            //  @ts-ignore
            quillEditor?.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const loadFile = (file: DocumentPicker.DocumentPickerAsset) => {
        setFiles([...files, file])
    }

    return (
        <View className='flex-1 flex-col  justify-between h-full'>
            <ScrollView>
                <ReactQuill
                    ref={quillRef}
                    value={value}
                    onChange={(currentValue) => setValue('text', currentValue)}
                    modules={modules}
                    formats={formats}
                    placeholder={!isLoading ? 'Or write by yourself...' : 'Generate a message...'}
                />
                {files.length > 0 && (
                    <View className='flex-col' style={{ gap: 4 }}>
                        {files.map((file) => (
                            <View
                                className='flex-row items-center justify-between max-w-[328px] bg-gray-100 rounded-base-x2'
                                style={{ gap: 8 }}>
                                <View className='flex-row justify-between items-center flex-1'>
                                    <Typography
                                        variant='body-list'
                                        className='text-button-divider pr-base-x2 pl-base-x2'>
                                        {file.name.replace(/(.{24})..+/, '$1...')}
                                    </Typography>
                                    <Typography variant='caption' className='text-sky-600'>
                                        {file.size}Kb
                                    </Typography>
                                </View>
                                <Button icon='X' className='p-base-x1' sizeIcon={24} />
                            </View>
                        ))}
                    </View>
                )}
            </ScrollView>
            <View className='justify-between flex-row items-center'>
                <Toolbar quillRef={quillRef} value={value} loadFile={loadFile} />
                <ButtonDouble onSubmit={onSubmit} />
            </View>
        </View>
    )
}
export default Editor
