import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { ITooltip } from './tooltip.interface'
import { Typography } from '@/components/ui'

const Tooltip: FC<PropsWithChildren<ITooltip>> = ({
	placement,
	content,
	children,
	timeout
}) => {
	const [isVisibleTooltip, setVisibleTooltip] = useState(false)
	const [tooltipPosition, setTooltipPosition] = useState({ left: 0 })

	const handleMouseEnter = () => {
		if (timeout) {
			timeoutId = setTimeout(() => {}, timeout)
		} else {
			setVisibleTooltip(true)
		}
	}

	const handleMouseLeave = () => {
		clearTimeout(timeoutId)
		setVisibleTooltip(false)
	}

	let timeoutId

	const tooltipRef = useRef(null)
	const childrenRef = useRef(null)

	useEffect(() => {
		return () => {
			clearTimeout(timeoutId)
		}
	}, [])

	useEffect(() => {
		if (isVisibleTooltip) {
			const childRect = childrenRef.current.getBoundingClientRect()
			const tooltipRect = tooltipRef.current.getBoundingClientRect()

			let left = 0

			switch (placement) {
				case 'top':
					left = childRect.left + (childRect.width - tooltipRect.width) / 2
					break
				case 'bottom':
					left = childRect.left + (childRect.width - tooltipRect.width) / 2
					break
				case 'left':
					left = childRect.left - tooltipRect.width
					break
				case 'right':
					left = childRect.right
					break
			}

			setTooltipPosition({ left })
		}
	}, [isVisibleTooltip, placement])
	return (
		<View
			className='relative text-white'
			onPointerEnter={() => handleMouseEnter()}
			onPointerLeave={() => handleMouseLeave()}
		>
			<View ref={childrenRef}>{children}</View>
			{isVisibleTooltip && (
				<View
					className='absolute bg-gray-800 rounded-base-x2 opacity-100 pl-base-x2 pr-base-x2 pt-[4px] pb-[4px]'
					style={{ left: tooltipPosition.left }}
					ref={tooltipRef}
				>
					<Typography variant='calout' nowrap className='text-white'>
						{content}
					</Typography>
				</View>
			)}
		</View>
	)
}

export default Tooltip
