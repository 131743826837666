import { useDate } from "@/shared/libs/hooks";
import { ITodo, IUpdateTodo } from "@/shared/types/todo.interface";
import { filterByCategory } from "./filters.helper";
import { ICountTodosTab } from "../types";

export const updateItem = (id: number, item: ITodo, todo: IUpdateTodo): ITodo => {
    if (item.id === id) {
        item.id = todo.id as number
        item.state = todo.state || 0;
        item.text = todo.text || item.text;
        item.text_extra = todo.text_extra || item.text_extra;
        item.due = todo.due  ? item.due : null
        item.date = todo.due ? useDate(todo.due) : ''
        item.is_favorite = todo.is_favorite || false;
        item.priority = todo.priority || item.priority;
        item.parent_id = todo.parent_id || item.parent_id;
        item.subtasks = todo.subtasks || item.subtasks

    }
    return item   
}

export const countTodosTab = (arr: ITodo[]): ICountTodosTab =>  ({
    all: filterByCategory(arr, 'all').length,
    today: filterByCategory(arr, 'today').length,
    tomorrow: filterByCategory(arr, 'tomorrow').length,
    important: filterByCategory(arr, 'important').length,
    completed: filterByCategory(arr, 'completed').length,
})

export const sortByComplited = (arr: any) => arr.sort((a: any, b: any) => a.state - b.state)