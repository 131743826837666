import { useState, useEffect } from "react"
import { TSummaryState } from "../../types"
import { IUseSummaryResult, IUseSummaryState } from "../types"
import { IBody, ISummaryExtra } from "@/shared/types/dashboard.interface"



export const useSummaryState = (
    body: IBody[] | ISummaryExtra[] | string, 
    { 
        defaultState = 'default',
        count = 0,
    }: IUseSummaryState
): IUseSummaryResult  => {

    const [summaryCount, setSummaryCount] = useState<number>(count)
	const [summaryState, setSummaryState] = useState<TSummaryState>(defaultState)

    useEffect(() => {
		summaryCount !== 0 
			? setSummaryState(body.length ? 'generated' : 'default')
			: setSummaryState('limited')
	}, [body, summaryCount])

    return {
        summaryCount,
        setSummaryCount,
        summaryState,
        setSummaryState
    }

}