import { FC, useState } from 'react'
import { TextInput, View } from 'react-native'
import { IInputSearch } from './inputSearch.interface'
import cn from 'clsx'
import { Icon } from '@/components/ui'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'

const InputSearch: FC<IInputSearch> = ({
	className,
	onChange,
	value,
	placeholder
}) => {
	const [focus, setFocus] = useState(false)
	const { bg, text, color } = useColors()
	const { colorScheme } = useColorScheme()

	return (
		<View
			className={cn(
				'w-fit rounded-base-x16 flex-row items-center pl-base-x2 pr-base-x2 ',
				colorScheme === 'light' && 'border border-gray-100',
				bg.inactiveSurface,
				className,
				focus && `border border-black ${bg.white}`
			)}
		>
			<Icon name='Search' color={focus ? color.black : color.inactiveIcons} />
			<TextInput
				autoCapitalize={'none'}
				onChangeText={onChange}
				value={value}
				underlineColorAndroid='transparent'
				placeholder={placeholder}
				onFocus={() => setFocus(!focus)}
				onBlur={() => setFocus(false)}
				className={cn(
					'text-base-body2 h-[36px] w-full pl-base-x1 mr-base-x2',
					text.inactiveText,
					bg.inactiveSurface,
					value && text.boldText,
					focus && bg.white
				)}
				style={{
					// @ts-ignore
					outline: 'none'
				}}
			/>
		</View>
	)
}

export default InputSearch
