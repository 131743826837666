import { FC, useState } from 'react'
import { ScrollView, View } from 'react-native'
import Header from './Header'
import TodoItem from '../common/TodoItem'
import { Button, Loader, Typography } from '@/components/ui'
import Filters from './Filters'
import { TSection } from '../../todo.interface'
import { useColors } from '@/hooks/useColors'
import cn from 'clsx'
import { useColorScheme } from 'nativewind'
import { useGetTodos, useRemoveTodo, useUpdateTodo } from '../../api'
import { useStore } from '@/shared/store'
import { ITodo } from '@/shared/types/todo.interface'
import TodoList from '../common/TodoList'
import AddTaskToDo from '@/components/screens/dashboard/components/AddTask'

interface IProps {}
const TodoBody: FC<IProps> = ({}) => {
    const countTodo = 100
    const [selectedTodo, setSelectedTodo] = useState(0)
    const [showAddTaskModal, setShowAddTaskModal] = useState(false)
    const { bg, text } = useColors()

    const { colorScheme } = useColorScheme()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'
    const { setAddTodoFormType } = useStore((state) => state)

    const { todosTab, isFetching } = useGetTodos()

    const {
        todo: selected,
        getTodo,
        sectionsTodosTab,
        filteredTodosTabCurrent,
        foundTodosTab,
        searchValueTab
    } = useStore((state) => state)
    
    const { updateTodo } = useUpdateTodo()
    const { removeTodo } = useRemoveTodo()

    const updateTask = (id: number, todo: ITodo) => {
        updateTodo(id, { ...todo })
    }

    const toggleVisibleList = () => {
        setShowAddTaskModal(!showAddTaskModal)
        setAddTodoFormType('task')
    }

    const selectTodo = () => {
        if (countTodo === selectedTodo || countTodo > selectedTodo) {
            setSelectedTodo(0)
        }
        if (!selectedTodo) {
            setSelectedTodo(countTodo)
        }
    }

    const sections = [
        {
            title: 'Tomorrow',
            content: <TodoList todos={sectionsTodosTab.tomorrow} />
        },
        {
            title: 'Today',
            content: <TodoList todos={sectionsTodosTab.today} />
        }
    ]

    return (
        <View
            className={cn('flex-1 px-base-x2 pt-base-x3 pb-base-x6flex-col border-x-[1px] ', deviderColor)}
            style={{ gap: 8 }}>
            <Header countTodo={countTodo} selectedTodo={selectedTodo} selectTodo={selectTodo} />
            {filteredTodosTabCurrent !== 'completed' && (
                <View>
                    <Button
                        style={{
                            //@ts-ignore
                            transition: 'all 0.3s ease-out'
                        }}
                        className={`w-full h-base-x12 mr-4 rounded-t-lg rounded-b-lg border-transparent py-4 px-2 ${
                            showAddTaskModal ? `${bg.hoverIconSurface} rounded-b-none` : ''
                        }`}
                        styleOnHover={`${bg.hoverIconSurface} ${showAddTaskModal ? bg.hoverIconSurface : ''}`}
                        classNameText={text.black}
                        colorIcon='sky'
                        variant='subheadline'
                        onPress={toggleVisibleList}
                        icon='PlusCircle'>
                        Add a task
                    </Button>
                    <AddTaskToDo
                        className='-mt-[25px]'
                        type='task'
                        showAddTaskModal={showAddTaskModal}
                        setShowAddTaskModal={setShowAddTaskModal}
                    />
                </View>
            )}
            <ScrollView contentInsetAdjustmentBehavior='automatic' className='flex-1 pr-base-x2'>
                <View className='flex-col' style={{ gap: 8 }}>
                    {!isFetching ? (
                        !foundTodosTab.length && !searchValueTab.length ? (
                            todosTab.length ? (
                                todosTab.map((todo, idx) => (
                                    <TodoItem
                                        key={idx}
                                        todo={todo}
                                        selected={selected.id === todo.id}
                                        removeTask={() => removeTodo(todo.id as number)}
                                        updateTask={updateTask}
                                        onPress={() => getTodo(todo.id as number)}
                                    />
                                ))
                            ) : (
                                <Typography variant='body'>You have no any todos.</Typography>
                            )
                        ) : (
                            foundTodosTab.map((todo, idx) => (
                                <TodoItem
                                    key={idx}
                                    todo={todo}
                                    selected={selected.id === todo.id}
                                    removeTask={() => removeTodo(todo.id as number)}
                                    updateTask={updateTask}
                                    onPress={() => getTodo(todo.id as number)}
                                />
                            ))
                        )
                    ) : (
                        <Loader text='Loading tasks' />
                    )}
                </View>
                {filteredTodosTabCurrent === 'all' &&
                (sectionsTodosTab.tomorrow || sectionsTodosTab.today) ? (
                    <Filters sections={sections as TSection[]} />
                ) : null}
            </ScrollView>
        </View>
    )
}

export default TodoBody
