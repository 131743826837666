export enum EMessageGenerateLength {
    short = 0,
    medium = 1,
    extended = 2,
    long = 3
}

export enum EMessageGenerateStyle {
    friendly = 0,
    approachable = 1,
    polite = 2,
    professional = 3
}
