import { FC, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { Button, Plug, Typography } from '@/components/ui'
import SubtasksItem from './SubtasksItem'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'
import { useStore } from '@/shared/store'
import { useRemoveTodo, useUpdateTodo } from '../../api'
import { ITodo } from '@/shared/types/todo.interface'
import AddTaskToDo from '@/components/screens/dashboard/components/AddTask'

interface IProps {}

const Subtasks: FC<IProps> = ({}) => {
    const [showAddTaskModal, setShowAddTaskModal] = useState(false)
    const { bg, text } = useColors()

    const { colorScheme } = useColorScheme()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'
    const { subtasks, setAddTodoFormType } = useStore((state) => state)

    const { updateTodo } = useUpdateTodo()
    const { removeTodo } = useRemoveTodo()

    const updateTask = (id: number, todo: ITodo) => {
        updateTodo(id, { ...todo })
    }

    const toggleVisibleList = () => {
        setShowAddTaskModal(!showAddTaskModal)
        setAddTodoFormType('subtask')
    }

    return (
        <View
            className={cn('p-base-x4 pr-base-x2 w-[300px] flex-col border-l-[1px]', deviderColor)}
            style={{ gap: 16 }}>
            <Typography variant='h3' className={text.boldText}>
                Subtasks
            </Typography>
            <View>
                <Button
                    style={{
                        //@ts-ignore
                        transition: 'all 0.3s ease-out'
                    }}
                    className={`w-full mr-4 rounded-t-lg rounded-b-lg border-transparent bg.hoverIconSurface py-4 px-2 ${
                        showAddTaskModal ? `${bg.hoverIconSurface} rounded-b-none` : ''
                    }`}
                    styleOnHover={`${bg.hoverIconSurface} ${showAddTaskModal ? bg.hoverIconSurface : ''}`}
                    colorIcon='primary'
                    sizeIcon={24}
                    variant='subheadline'
                    classNameText={text.black}
                    onPress={toggleVisibleList}
                    icon='PlusCircle'>
                    Add new subtask
                </Button>

                <AddTaskToDo
                    className='-mt-[25px]'
                    type='subtask'
                    showAddTaskModal={showAddTaskModal}
                    setShowAddTaskModal={setShowAddTaskModal}
                />
            </View>
            {subtasks.length ? (
                <>
                    <ScrollView contentInsetAdjustmentBehavior='automatic' className='flex-col pr-base-x2'>
                        {subtasks.map((subtask) => (
                            <SubtasksItem
                                key={subtask.id}
                                subtask={subtask}
                                updateTask={updateTask}
                                onRemove={() => removeTodo(subtask.id as number)}
                            />
                        ))}
                    </ScrollView>
                </>
            ) : (
                <Plug text='Manage your subtasks here' size='small' className='pt-base-x12' />
            )}
        </View>
    )
}

export default Subtasks
