import AsyncStorage from '@react-native-async-storage/async-storage'

export const getCourseCompleted = async () => {
	const courseCompleted = JSON.parse(
		(await AsyncStorage.getItem('courseCompleted')) || 'false'
	)
	return courseCompleted || false
}

export const saveCourseCompleted = async (value: boolean) => {
	await AsyncStorage.setItem('courseCompleted', JSON.stringify(value))
}

export const getAgreeTerms = async () => {
	const courseCompleted = JSON.parse(
		(await AsyncStorage.getItem('agreeTerms')) || 'false'
	)
	return courseCompleted || false
}

export const setAgreeTerms = async (value: boolean) => {
	await AsyncStorage.setItem('agreeTerms', JSON.stringify(value))
}

export const getVisibleComposeInfo = async () => {
	const courseCompleted = JSON.parse(
		(await AsyncStorage.getItem('visibleComposeInfo')) || 'false'
	)
	return courseCompleted || false
}

export const saveVisibleComposeInfo = async (value: boolean) => {
	await AsyncStorage.setItem('visibleComposeInfo', JSON.stringify(value))
}
