import { FC, ReactNode, useState } from 'react'
import { View } from 'react-native'
import { MailChat, ModalNotification, Notification } from '../index'
import { useMailPosition } from '@/context/MailPositionContext'
import cn from 'clsx'
import { useTypedRoute } from '@/hooks/useTypedRoute'

interface IProps {
	sections: ReactNode
	activeChat: any
}

const MailList: FC<IProps> = ({ sections, activeChat }) => {
	const { params } = useTypedRoute<'Inbox'>()
	const path = params?.path
	const { position } = useMailPosition()
	const [visibleModal, setVisibleModal] = useState(true)

	return (
        <>
            {path === 'spam' && (
                <Notification
                    classNameButton='text-primary'
                    text='Messages that have been in Spam more than 30 days will be automatically deleted'
                    buttonText='Delete all spam now'
                    onClick={() => undefined}
                />
            )}
            {path === 'trash' && (
                <Notification
                    classNameButton='text-primary'
                    text='Messages that have been in Trash more than 30 days will be automatically deleted'
                    buttonText='Empty Trash now'
                    onClick={() => undefined}
                />
            )}

            <View
                className={cn(
                    'flex-row flex-1 justify-between  relative border-t',
                    ['spam', 'trash'].includes(path as string) ? 'border-t-white mt-0' : 'border-t-gray-300'
                )}>
                {((position === 'horizontal' && !activeChat) || position === 'vertical') && sections}

                {position === 'vertical' && (
                    <View className='flex-1 flex-row'>
                        <View className='w-[2px] h-fit bg-black mx-2 mt-base-x2 rounded-base-x9 ' />
                        <MailChat activeChat={activeChat} path={path} />
                    </View>
                )}
                {position === 'horizontal' && activeChat && <MailChat activeChat={activeChat} path={path} />}
                {path === 'spam' && visibleModal && (
                    <ModalNotification
                        text='Conversation unmarked as spam and Moved to Inbox. Future messages from this sender will be sent to inbox.'
                        onClose={() => setVisibleModal(!visibleModal)}
                    />
                )}
            </View>
        </>
    )
}

export default MailList
