import { FC } from 'react'
import { Text, View } from 'react-native'
import { Icon, LoginButton, Typography } from '@/components/ui'
import { useAuthMutations } from '@/components/screens/auth/useAuthMutations'

const Auth: FC = () => {
	const upperBlocksInfo = [
		{
			title: 'No need to wade through clutter!',
			icon: 'ListOrdered',
			text: 'Get a concise and short Executive Summary of all new messages at a glance'
		},
		{
			title: 'Want more details?',
			icon: 'Filter',
			text: 'Click on any part of the Executive Summary to view AI-filtered content from the original email'
		},
		{
			title: 'Concerned about overlooking key details?',
			icon: 'Subtitles',
			text: 'Review your email threads displayed as chat conversations or check the AI-created highlights'
		}
	]

	const lowerBlocksInfo = [
		{
			title: 'Replying?',
			icon: 'Wand2',
			text: 'Use the prompts for swift responses and modify your reply without leaving the page'
		},
		{
			title: 'Spot a crucial task?',
			icon: 'ListTodo',
			text: 'Directly add AI-suggested actions to your To-Do List'
		}
	]

	const { loginSync } = useAuthMutations()

	return (
		<View className='items-center justify-center'>
			<View className='max-w-[945px] '>
				<Typography
					variant='h2'
					className='text-center p-base-x2 mt-base-x4 text-gray-900'
				>
					Turn your traditional inbox into an{' '}
					<Text className='text-sky-500'>AI-Powered Dashboard</Text> that lets
					you grasp the main points of your emails without reading them
				</Typography>
				<View className='gap-y-base-x9 pt-base-x2 mb-base-x6'>
					<View className='flex-row justify-between'>
						{upperBlocksInfo.map(block => (
							<View
								key={block.title}
								className='p-base-x4 flex-row gap-x-base-x3 rounded-base-x6 bg-white'
							>
								<Icon name={block.icon} className='pt-base-x2' />
								<View className='flex-col max-w-[230px]'>
									<Typography
										variant='h4'
										className='text-sky-500 p-base-x2 flex-1'
									>
										{block.title}
									</Typography>
									<Typography variant='body' className='p-base-x2'>
										{block.text}
									</Typography>
								</View>
							</View>
						))}
					</View>
					<View className='flex-row justify-center gap-x-base-x9'>
						{lowerBlocksInfo.map(block => (
							<View
								key={block.title}
								className='p-base-x4 flex-row gap-x-base-x3 rounded-base-x6 bg-white'
							>
								<Icon name={block.icon} className='pt-base-x2' />
								<View className='flex-col max-w-[230px]'>
									<Typography variant='h4' className='text-sky-500 p-base-x2'>
										{block.title}
									</Typography>
									<Typography variant='body' className='p-base-x2'>
										{block.text}
									</Typography>
								</View>
							</View>
						))}
					</View>
				</View>
				<View className='items-center'>
					<Typography variant='h3' className='p-base-x6 text-center'>
						All within a single, streamlined page
					</Typography>
					<LoginButton onSubmit={() => loginSync()} />
					<Typography
						variant='body'
						className='p-base-x2 text-gray-500 mt-base-x2'
					>
						Try Scive Premium 7 days free! No automatic payments!
					</Typography>
				</View>
			</View>
		</View>
	)
}

export default Auth
