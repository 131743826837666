import { StateCreator } from 'zustand'
import { ISummarySlice } from '../types'

export const summarySlice: StateCreator<ISummarySlice> = (set, get) => ({
    summary: [],
    summaryExtra: {
        todos: [],
        highlights: [],
        detailedSummary: []
    },
    mailsCount: 0,

    setSummary: (summary) => {
        const res = summary.map((item) => ({
            sentence: item.sentence,
            messageId: item.message_ids[0]
        }))

        return set(() => ({
            summary: res,
            mailsCount: res.length
        }))
    },
    setSummaryExtra: (summaryExtra) => {
        const summary = {
            ...get().summaryExtra,
            todos: summaryExtra.todos || [],
            highlights: summaryExtra.highlights || [],
            detailedSummary: summaryExtra.detailed_summary || []
        }
        return set(() => ({
            summaryExtra: summary
        }))
    },
    removeSummaryTodo: (todo) => {
        const { todos } = get().summaryExtra

        const todosList = todos.filter((item) => item.text !== todo.text)

        return set(() => ({
            summaryExtra: {
                ...get().summaryExtra,
                todos: todosList
            }
        }))
    }
})
