import { getAuthUrl } from "@/config/api.config"
import { headers } from "../api/headers"
import { request } from "../api/request.api"
import { IUser } from "@/shared/types/user.interface"

export const settingsService = {
    async getUserProfile () {
        return request<IUser>({
            url: getAuthUrl('/user/'),
            method: 'GET',
            headers: await headers()
        })
    }
}