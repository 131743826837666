import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { DashboardService } from "@/services/dashboard";
import { TMessageId } from "../types";
import { useStore } from "@/shared/store";


export const useGetSummaryExtra = () => {
    const [messageId, setMessageId] = useState<TMessageId>(null)
    const [enabled, setEnabled] = useState<boolean>(false)

    const { setSummaryExtra, removeSummaryTodo } = useStore((state) => state)

    const queryResult = useQuery(
		['get_summary_extra'],
		() => DashboardService.getSummaryExtra(messageId),
		{
			enabled,
			onSuccess(res) {
                res && setSummaryExtra(res)
			},
		}
	);

    useEffect(() => {
		if (enabled) {
			setEnabled(false);
		}
	}, [enabled])

    const fetchSummaryExtra = (msgId: TMessageId) => {
		setMessageId(msgId)
		setEnabled(true)
	};

    return {
        fetchSummaryExtra,
		removeSummaryTodo,
        messageId,
		setMessageId,
        ...queryResult,
    }
}