import { FC } from 'react'
import { View } from 'react-native'
import { Body, Footer, Header, Loader } from '@/components/ui'
import { StyledProps, useColorScheme } from 'nativewind'
import { useMailPosition } from '@/context/MailPositionContext'
import cn from 'clsx'
import { useSummaryState } from './hooks/useSummaryState.hook'
import { TMessageId } from './types'
import { ISummaryExtra } from '@/shared/types/dashboard.interface'
import { useStore } from '@/shared/store'

interface IProps {
	className?: StyledProps<string>
	messageId: TMessageId
	isLoading?: boolean | null
}


const DetailedSummary: FC<IProps> = ({ className, messageId, isLoading }) => {
    const { position } = useMailPosition()
	const { colorScheme } = useColorScheme()
	const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'
	
	const { detailedSummary } = useStore((state) => state.summaryExtra)
    
	const { summaryCount, summaryState } =  useSummaryState(detailedSummary, { count: 5 })
	
	return (
        <View
            className={cn(
                'pt-base-x5 pb-base-x2 pl-base-x2 flex-1 min-h-[184px] justify-between border-b-[1px]',
                deviderColor,
                className
            )}>
            <Header
                title='Message Summary'
                subtitle=''
                counter={{ count: summaryCount, subject: 'left' }}
                status={summaryState}
            />
            {!isLoading ? (
                <Body
                    body={detailedSummary[0]?.text || ''}
                    placeholder='Here you will see the AI-generated details of the selected email'
                    status={summaryState}
                />
            ) : (
                <Body>
                    <Loader text='Generate detail summary' />
                </Body>
            )}

            {position === 'horizontal' && <Footer messageId={messageId} />}
        </View>
    )
}

export default DetailedSummary
