import { FC } from 'react'
import { View } from 'react-native'
import { Header } from '@/components/screens/settings/components'
import { ButtonPlain, Typography } from '@/components/ui'
import OrderTable from './components/OrderTable'
import CurrentPlanInfo from './components/CurrentPlanInfo'
import { useColorScheme } from 'nativewind'
import cn from 'clsx'

const Billing: FC = () => {
	const { colorScheme } = useColorScheme()
	const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

	return (
        <View className={cn('flex-1 p-base-x2 flex-col border-l-[1px] ', deviderColor)} style={{ gap: 8 }}>
            <Header title='Billing ' />
            <View className='mt-base-x5 flex ' style={{ gap: 16 }}>
                <View className='flex pl-base-x4 '>
                    <Typography variant='h4' className='text-gray-400 font-semibold'>
                        Order history
                    </Typography>
                </View>
                <View className='flex-row ' style={{ gap: 24 }}>
                    <OrderTable />
                    <CurrentPlanInfo />
                </View>
                <View className='flex-row' style={{ gap: 16 }}>
                    <View className='flex-col pl-base-x4 pr-base-x4'>
                        <Typography variant='h4' className='text-gray-400 font-semibold'>
                            Payment method
                        </Typography>
                    </View>
                    <ButtonPlain classNameText='text-sky'>+ Add new</ButtonPlain>
                </View>
            </View>
        </View>
    )
}

export default Billing
