import { FC } from 'react'
import { ScrollView } from 'react-native'
import { TableRow } from '../index'
import cn from 'clsx'
import { IThread } from '@/shared/types/dashboard.interface'
import { Loader } from '@/components/ui'

interface IProps {
    threads: IThread[]
    isLoading: boolean
}

const TableList: FC<IProps> = ({ threads, isLoading }) => {
	return (
		<ScrollView className={cn('flex-col flex-1 ')}>
			{
				!isLoading
					? threads.length ? threads.map(item => {
							return (
								<TableRow
									key={item.id}
									id={item.id}
									thread={item}
									read={false}
								/>
							)
						}) : null
					: <Loader text='Loading threads' />
			}
		</ScrollView>
	)
}

export default TableList
