import { getTodosUrl } from "@/config/api.config"
import { request } from "../api/request.api"
import { headers } from "../api/headers"
import { IAddTodosResponse,  IRemoveTodoResponse,  ITodo } from "@/shared/types/todo.interface"
import { TMessageId } from "@/components/ui/summary/types"

export const TodosService = {
    async getTodos() {
        return request<ITodo[]>({
            url: getTodosUrl('/'),
            method: 'GET',
            headers: await headers()
        })
    },
    
    async getTodo(todoId: TMessageId) {
        if(todoId) {
            return request<ITodo>({
                url: getTodosUrl(`/${todoId}`),
                method: 'GET',
                headers: await headers(),
            })
        }
        return
    },
    
    async updateTodo(todoId: number | null, data: ITodo | null) {
        if(todoId && !!data) {
            return request<ITodo>({
                url: getTodosUrl(`/${todoId}`),
                method: 'PUT',
                headers: await headers(),
                data: { ...data },
            })
        }
        return
    },

    async addTodo(data: ITodo | null) {
        if (data) {
            return request<IAddTodosResponse>({
                url: getTodosUrl('/'),
                method: 'POST',
                headers: await headers(),
                data: { ...data },
            })
        }
        return
    },
    async removeTodo(todoId: number | null) {
        if(todoId) {
            return request<IRemoveTodoResponse>({
                url: getTodosUrl(`/${todoId}`),
                method: 'DELETE',
                headers: await headers(),
            })
        }
        return
    },
}