import { getMessagesUrl, getThreadsUrl } from "@/config/api.config"
import { request } from "../api/request.api"
import { headers } from "../api/headers"
import { IGetThreadResponse, ISendGenerateMessage, ISendGenerateMessageResponse, ISendMessage, ISendMessageResponse } from "@/shared/types/dashboard.interface"


export const ThreadsService = {
    async getThreads() {
        return request<IGetThreadResponse>({
            url: getThreadsUrl('/direct_list'),
            method: 'GET',
            headers: await headers()
        })
    },

    async sendMessage(message: ISendMessage) {
        return request<ISendMessageResponse>({
            url: getMessagesUrl('/direct_send'),
            method: 'POST',
            data: message,
            headers: await headers()
        })
    },

    async sendGenerateMessage(message: ISendGenerateMessage) {
        return request<ISendGenerateMessageResponse>({
            url: getMessagesUrl('/user_prompt'),
            method: 'POST',
            data: message,
            headers: await headers()
        })
    }
}