import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { DashboardService } from "@/services/dashboard/dashboard.api";
import { useStore } from "@/shared/store";

export const useGetSummaryMessages = () => {
	const [refreshFromLlm, setRefreshFromLlm] = useState<boolean>(false)
	const [enabled, setEnabled] = useState<boolean>(false)

	const { summary, mailsCount, setSummary } = useStore(state => state)
	
	useEffect(() => {
		refreshFromLlm && setSummary([])
	}, [refreshFromLlm])

	useEffect(() => {
		if (enabled) {
			setEnabled(false);
		}
	}, [enabled])

    const queryResult = useQuery(
		['get_summary_messages'],
		() => DashboardService.getSummaryMessages(refreshFromLlm),
		{
			enabled,
			onSuccess({ data }) {
				if('summary' in data) {
					setSummary(data.summary)
				}
				refreshFromLlm  && setRefreshFromLlm(false)
			}
		}
	);

    return {
		body: summary,
        mailsCount,
		setEnabled,
		refresh: setRefreshFromLlm,
        ...queryResult,
    }
}