import axios from 'axios'
import { errorCatch } from '@/services/api/error.api'
import { deleteTokenStorage, getToken } from '@/services/auth/auth.helper'
import { API_URL } from '@/config/api.config'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true

const instance = axios.create({
	baseURL: API_URL
})

instance.interceptors.request.use(async config => {
	const token = await getToken()

	if (config.headers && token && typeof token === 'string') {
		config.headers.Authorization = `Token ${token}`
	}
	return config
})

instance.interceptors.response.use(
	config => config,
	async error => {
		const originalRequest = error.config

		if (
			(error.response?.status === 401 ||
				errorCatch(error) === 'token expired' ||
				errorCatch(error) === 'token must be provided') &&
			error.config &&
			!error.config._isRetry
		) {
			originalRequest._isRetry = true
			try {
				return instance.request(originalRequest)
			} catch (error) {
				if (errorCatch(error) === 'token expired') await deleteTokenStorage()
			}
		}

		throw error
	}
)
export default instance
