import { Dispatch, FC } from 'react'
import { Pressable, TouchableOpacity } from 'react-native'
import { Typography } from '@/components/ui'
import { Clock8, Trash2 } from 'lucide-react'
import cn from 'clsx'

interface MenuMoreSubtaskProps {
    className?: string
    classNameIcon?: string
    onRemove: () => void
    setIsHovered: Dispatch<React.SetStateAction<boolean>>
}

const MenuMoreSubtask: FC<MenuMoreSubtaskProps> = ({ setIsHovered, onRemove, className }) => {
    return (
        <Pressable
            onHoverIn={() => setIsHovered(true)}
            onHoverOut={() => setIsHovered(false)}
            style={{ zIndex: 1000 }}
            className={cn(
                'w-[150px] border-transparent rounded-lg  bg-white shadow-md top-[0px] left-[-120]',
                className
            )}>
            <TouchableOpacity
                activeOpacity={1}
                className='flex-row  items-center h-[36px] border-b border-b-gray-300 z-[1000 ] active:bg-gray-100'>
                <Clock8 size={20} style={{ marginRight: 8, color: 'black', paddingLeft: 8 }} />
                <Typography variant={'subheadline'} className='text-gray-500'>
                    Select a date
                </Typography>
            </TouchableOpacity>
            <TouchableOpacity
                activeOpacity={1}
                className='flex-row items-center h-[36px] border-b border-b-gray-300 z-[1000] active:bg-gray-100'
                onPress={onRemove}>
                <Trash2 size={20} style={{ marginRight: 8, color: 'black', paddingLeft: 8 }} />
                <Typography variant={'subheadline'} className='text-gray-500'>
                    Delete
                </Typography>
            </TouchableOpacity>
        </Pressable>
    )
}

export default MenuMoreSubtask
