import { FC } from 'react'
import { View } from 'react-native'
import { Navbar } from '@/components/ui'
import Subtasks from './components/subtasks/Subtasks'
import TodoBody from './components/todoBody/TodoBody'
import { useStore } from '@/shared/store'

const Todo: FC = () => {
	const { countTodosTab, selectedTodoId } = useStore((state) => state)

	const navbar = [
        { icon: 'Sun', title: 'Today', path: undefined, countUnread: countTodosTab.today },
        { icon: 'Haze', title: 'Tomorrow', path: 'tomorrow', countUnread: countTodosTab.tomorrow },
        { icon: 'Star', title: 'Important', path: 'important', countUnread: countTodosTab.important },
        { icon: 'MenuSquare', title: 'All', path: 'all', countUnread: countTodosTab.all },
        { icon: 'CheckCircle2', title: 'Completed', path: 'completed', countUnread: countTodosTab.completed }
    ]

	
	return (
        <View className='flex-row flex-1 justify-between' style={{ gap: 4 }}>
            <Navbar navbar={navbar} />
            <TodoBody />
            {selectedTodoId && <Subtasks />}
        </View>
    )
}

export default Todo
