import instance from '@/services/api/interceptors.api'
import Toast from 'react-native-toast-message'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { errorCatch } from '@/services/api/error.api'
import { deleteTokenStorage } from '../auth/auth.helper'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { EnumStorage } from '@/shared/types/auth.interface'
import { getAuthUrl } from '@/config/api.config'

export const request = async <T>(config: AxiosRequestConfig) => {
	const onSuccess = (response: AxiosResponse<T>) => response.data

	const onError = async (error: AxiosError<T>) => {
		const unauthorized = error.response?.status === 401

		if (unauthorized) {
			Toast.show({
				type: 'error',
				text1: unauthorized && 'Unauthorized',
				text2: 'Redirecting to login...'
			})
			const data = await axios.post(getAuthUrl(`/logout/`))
			if (data.status === 200) {
				await deleteTokenStorage()
				await AsyncStorage.removeItem(EnumStorage.USER)
			}
		} else {
			Toast.show({
				type: 'error',
				text1: 'Request error',
				text2: errorCatch(error)
			})
		}

		return Promise.reject(error)
	}

	return instance(config).then(onSuccess).catch(onError)
}
