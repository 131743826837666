import { FC, ReactNode, useEffect, useState } from 'react'
import { useTypedRoute } from '@/hooks/useTypedRoute'
import { ComposePopup, MailHeader, MailList, TableList } from './components'
import { View } from 'react-native'
import { useMailPosition } from '@/context/MailPositionContext'
import { Navbar } from '@/components/ui'
import cn from 'clsx'
import { useColorScheme } from 'nativewind'
import { useGetThreads } from './api'

const Inbox: FC = () => {
	const { position } = useMailPosition()
	const { params } = useTypedRoute<'Inbox'>()
	const [activeChat, setActiveShat] = useState<any>(null)
	const countLetters = 100
	const [selectedLetters, setSelectedLetters] = useState(0)
	const [sections, setSections] = useState<ReactNode>([])
	
	const [enabled, setEnabled] = useState<boolean>(false)
	const { threads, isLoading } = useGetThreads(enabled)

	const { colorScheme } = useColorScheme()
	const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

	const fetchThreads = () => setEnabled(true);

	useEffect(() => {
		!threads.length && fetchThreads()
	}, [threads])

	useEffect(() => {
		enabled && setEnabled(false);
	}, [enabled])
	
	useEffect(() => {
		if (params?.id) {
			setActiveShat({ title: 'Title' })
		} else {
			setActiveShat(null)
		}
	}, [params])

	useEffect(() => {
		setActiveShat(null)
	}, [])


	const selectLetters = () => {
		if (countLetters === selectedLetters || countLetters > selectedLetters) {
			setSelectedLetters(0)
		}
		if (!selectedLetters) {
			setSelectedLetters(countLetters)
		}
	}

	useEffect(() => {
		// setActiveShat(null)
		if (params?.path === undefined) {
			setSections(<TableList threads={threads} isLoading={isLoading} />)
		} else {
			setSections(<TableList threads={threads} isLoading={isLoading} />)
		}
	}, [params?.path, threads])

	const navbar = [
		{ icon: 'Mail', title: 'Inbox', path: undefined, countUnread: 12 },
		{ icon: 'Send', title: 'Sent', path: 'sent' },
		{
			icon: 'AlertOctagon',
			title: 'Spam',
			path: 'spam',
			countUnread: 5,
			isRedCounter: true
		},
		{ icon: 'Trash2', title: 'Trash', path: 'trash' }
	]

	return (
        <View className='flex-row flex-1  ' style={{ gap: 4 }}>
            {position === 'horizontal' && activeChat ? null : <Navbar navbar={navbar} />}
            <View
                className={cn(
                    'flex-1 relative z-20 px-base-x1 pt-base-x3 pb-base-x6  border-l-[1px]',
                    deviderColor
                )}
			>
                <MailHeader
                    countLetters={countLetters}
                    selectedLetters={selectedLetters}
                    selectLetters={selectLetters}
                />
                <MailList sections={sections} activeChat={activeChat} />
                <ComposePopup />
            </View>
        </View>
    )
}

export default Inbox
