import { FC, useState } from 'react'
import { View } from 'react-native'
import { Button, Typography } from '@/components/ui'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'
import { ISummaryExtra } from '@/shared/types/dashboard.interface'

export interface ITodoItem {
	todo: ISummaryExtra
	addTask: () => void
}

const TodoItem: FC<ITodoItem> = ({ todo, addTask }) => {
	// !TEST !CHECK !UPDATE
	const due = null
	const done = false


	const overdue = due === 'Yesterday'
	const { colorScheme } = useColorScheme()
	const [active, setActive] = useState(false)
	const { text: textColor, color } = useColors()
	
	return (
        <View className='pt-base-x1 pb-base-x1 flex-col items-center' style={{ gap: 8 }}>
            <View className='flex-row justify-between items-center w-full'>
                <View className='flex-row items-center max-w-[80%]'>
                    <Button
                        icon='Plus'
                        className='p-base-x2'
                        sizeIcon={32}
                        colorIcon={done ? color.black : color.activeSky}
                        onPress={addTask}
                    />
                    <Typography variant='body-list' className={cn(textColor.boldText, overdue && 'text-red')}>
                        {todo?.text}
                    </Typography>
                </View>
                <Button
                    icon='Star'
                    colorIcon={active ? color.star : color.black}
                    fillIcon={active ? (colorScheme === 'light' ? '#1F2937' : '#38BDF8') : ''}
                    sizeIcon={20}
                    onPress={() => setActive(!active)}
                />
            </View>
        </View>
    )
}

export default TodoItem


