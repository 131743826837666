import { useMutation } from "@tanstack/react-query"
import { TodosService } from "@/services/togos";
import { useState } from "react";
import { ITodo, IUpdateTodo } from "@/shared/types/todo.interface";
import { useStore } from "@/shared/store";

export const useAddTodo = () => {
	const [newTodo, setNewTodo] = useState<ITodo | null>(null);
	
	const { addTodo: addTodoStore } = useStore((state) => state)

    const mutationResult = useMutation(
		['add_todos'],
		() => TodosService.addTodo(newTodo),
		{
			onSuccess(res) {
				if (res) {
					addTodoStore({
						id: res.detail.id,
						state: newTodo && newTodo.state || 0,
						text: newTodo && newTodo.text || '',
						due: newTodo && newTodo.due ,
						text_extra: newTodo && newTodo.text_extra,
						is_favorite: newTodo && newTodo.is_favorite || false,
						priority: newTodo && newTodo.priority || 'low',
						parent_id: newTodo && newTodo.parent_id,						
					})
					return 
				}
				addTodoStore({})
				return 
			}
		}
	);

	const addTodo = async (data: IUpdateTodo) => {
		const newTodo: ITodo = {
			state: data.state || 0,
			text: data.text || '',
			text_extra: data.text_extra || '',
			is_favorite: data.is_favorite || false,
			priority: data.priority || 'low',
			parent_id: data.parent_id || null,
			due: data.due || null,
		}
		await setNewTodo(newTodo)
		await mutationResult.mutate()
	}


    return {
		addTodo,
        ...mutationResult,
    }
}