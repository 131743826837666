import { FC, useEffect, useState } from 'react'
import { View } from 'react-native'
import Accordion from 'react-native-collapsible/Accordion'
import { TSection } from '../../todo.interface'
import { Icon, Typography } from '@/components/ui'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'

interface IFilters {
	sections: TSection[]
}

const Filters: FC<IFilters> = ({ sections }) => {
	const [activeSections, setActiveSections] = useState<number[]>([])
	const { colorScheme } = useColorScheme()
	const { color, text } = useColors()

	useEffect(() => {
		const lenghtArray = Array.from(
			{ length: sections.length },
			(_, index) => index
		)

		if (lenghtArray.length !== activeSections.length) {
			setActiveSections(
				Array.from({ length: sections.length }, (_, index) => index)
			)
		}
	}, [sections])

	function renderHeader(section: TSection, _: any, isActive: boolean) {
		return (
			<View
				style={{
					backgroundColor: colorScheme === 'light' ? 'white' : 'transparent'
				}}
				className='p-base-x2'
			>
				<View className='items-center flex-row' style={{ gap: 8 }}>
					<Icon
						name='ChevronUp'
						size={24}
						color={color.black}
						className={cn(isActive && 'transition-all rotate-180')}
					/>
					<Typography variant='body-bold' className={text.boldText}>
						{section.title}
					</Typography>
				</View>
			</View>
		)
	}

	function renderContent(section: TSection) {
		if (section.content)
			return <View className='mb-base-x4 mt-base-x4'>{section.content}</View>

		return (
			<Typography
				variant='body'
				className={cn(
					'h-[44px] pl-base-x2 flex items-center pr-base-x2',
					text.boldText
				)}
			>
				Hooray! You don't have a new email to work with.
			</Typography>
		)
	}
	return (
		<Accordion
			align='bottom'
			sections={sections}
			activeSections={activeSections}
			expandMultiple={true}
			renderHeader={renderHeader}
			renderContent={renderContent}
			onChange={sections => setActiveSections(sections as any)}
		/>
	)
}

export default Filters
