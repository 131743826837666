import { FC, useEffect, useState } from 'react'
import { View } from 'react-native'
import {
	Button,
	Icon,
	SizeSelector,
	SliderControl,
	Typography
} from '@/components/ui'
import {
	getVisibleComposeInfo,
	saveVisibleComposeInfo
} from '@/services/app.helper'
import { Pressable } from 'react-native-web-hover'
import cn from 'clsx'
import { TSize } from '../compose.interface'
import { useColors } from '@/hooks/useColors'
import { useStore } from '@/shared/store'

interface TProps {
	size: TSize
	setSize: (value: TSize) => void
	onlyMax?: boolean
}

const Header: FC<TProps> = ({ size, setSize, onlyMax }) => {
	const [visibleComposeInfo, setVisibleComposeInfo] = useState(false)
	const { bg, text, color } = useColors()
    const { sendMessageForm, setSendMessageForm } = useStore((state) => state)


	useEffect(() => {
		const fetchData = async () => {
			const visible = await getVisibleComposeInfo()
			setVisibleComposeInfo(visible)
		}

		fetchData()
	}, [])

	const onSetVisibleComposeInfo = async () => {
		await saveVisibleComposeInfo(false)
		setVisibleComposeInfo(false)
	}

	return (
        <View className='justify-between items-center flex-row'>
            {size === 'max' ? (
                <View className='items-center flex-row' style={{ gap: 16 }}>
                    {visibleComposeInfo && (
                        <Pressable>
                            {({ hovered }) => (
                                <View
                                    className={cn(
                                        'relative rounded-base-x2 max-w-[125px] pl-base-x2 pr-base-x2 pt-base-x1 pb-base-x1',
                                        bg.infoSurface,
                                        hovered && 'bg-gray-500'
                                    )}>
                                    {hovered && (
                                        <Icon
                                            name='X'
                                            size={12}
                                            className='p-base-x1 absolute top-0 right-0'
                                            color='black'
                                            onPress={onSetVisibleComposeInfo}
                                        />
                                    )}
                                    <Typography variant='caption' className={cn(text.white)}>
                                        Personalize AI reply parameters
                                    </Typography>
                                </View>
                            )}
                        </Pressable>
                    )}
                    <SliderControl
                        title={{ min: 'Short', max: 'Long' }}
                        value={sendMessageForm.length || 0}
                        onChange={(value) => setSendMessageForm('length', value)}
                    />
                    <SliderControl
                        title={{ min: 'Friendly', max: 'Professional' }}
                        value={sendMessageForm.style || 0}
                        onChange={(value) => setSendMessageForm('style', value)}
                    />
                </View>
            ) : (
                <Typography variant='body' className={cn(text.white)}>
                    Type to quick respond or use Scive power...
                </Typography>
            )}
            <View className='flex-row items-center' style={{ gap: 24 }}>
                {size === 'max' && <SizeSelector />}
                {!onlyMax && (
                    <Button
                        icon={size === 'max' ? 'MoveDown' : 'MoveUp'}
                        sizeIcon={20}
                        className='w-base-x8 h-base-x8 flex items-center justify-center rounded-base-x2 '
                        styleOnHover={bg.hoverSurface}
                        colorIcon={color.black}
                        onPress={() => setSize(size === 'max' ? 'min' : 'max')}
                    />
                )}
            </View>
        </View>
    )
}

export default Header
