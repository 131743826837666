import { FC, useEffect } from 'react'
import { View } from 'react-native'
import { INavbarItemProps } from './navbar.interface'
import cn from 'clsx'
import { Button, Icon, Typography } from '@/components/ui'
import { useTypedNavigation } from '@/hooks/useTypedNavigation'
import { useRoute } from '@react-navigation/native'
import { useColors } from '@/hooks/useColors'
import { useStore } from '@/shared/store'
import { TTodoCategories } from '@/shared/store/types'
import { useTypedRoute } from '@/hooks/useTypedRoute'

const NavbarItem: FC<INavbarItemProps> = ({ navItem }) => {
	const { title, icon, path, isRedCounter, countUnread, disabled, } = navItem

	const route = useRoute()
	const currentPage = route.name
	// @ts-ignore
	const { params } = useTypedRoute<typeof currentPage>()
	// @ts-ignore
	const currentPath = params?.path
	const { navigate } = useTypedNavigation()
	const { bg, color, text } = useColors()
	const { setTodosTabCategory, filteredTodosTabCurrent } = useStore()

	const onPress = () => {
		if (disabled) return
		setTodosTabCategory(path as TTodoCategories)

		if (path) {
			// @ts-ignore
			navigate(currentPage, { path })
		} else {
			// @ts-ignore
			navigate(currentPage)
		}
	}
	return (
		<Button
			key={title}
			styleOnHover={!disabled ? bg.inactiveSurface : ''}
			onPress={onPress}
			className={cn(
				'pt-base-x1 pb-base-x1 pr-base-x2 flex-row justify-between items-center rounded-base-x2',
				currentPath === path && bg.inactiveSurface
			)}
		>
			<View className='flex-row items-center'>
				<View
					className={cn(
						'w-[6px] h-[24px] rounded-base-x2',
						currentPath === path && 'bg-sky'
						
					)}
				/>
				<Icon
					name={icon}
					size={20}
					className='p-base-x2'
					color={disabled ? color.disabledIcons : color.black}
				/>
				<Typography
					variant='body-list'
					className={cn(text.boldText, disabled && text.disabledButton)}
				>
					{title}
				</Typography>
			</View>
			{!!countUnread && (
				<Typography
					variant='label-date'
					className={cn(
						'pl-[2px] pr-[2px] items-center justify-center flex rounded-base-x4 bg-red h-base-x4 min-w-[16px]',
						isRedCounter
							? `'bg-red-400' ${text.white}`
							: `${bg.hoverOverHoverSurface} text-light-text`
					)}
				>
					{countUnread}
				</Typography>
			)}
		</Button>
	)
}

export default NavbarItem
