import { getMessagesUrl } from "@/config/api.config"
import { request } from "../api/request.api"
import {
    IGetDetailedMessageResponse,
    IGetSummaryMessagesResponse,
    IGetHighlightResponse,
    IGetSummaryExtraResponse
} from "@/shared/types/dashboard.interface"
import { TMessageId } from "@/components/ui/summary/types"
import { headers } from "../api/headers"

export const DashboardService = {
    async getSummaryMessages(refresh_from_llm = false) {
        return request<IGetSummaryMessagesResponse>({
            url: getMessagesUrl(`/summary${refresh_from_llm ? '?refresh_from_llm=true' : '' }`),
            method: 'GET',
            headers: await headers()
        })
    },
    
    async refreshSummaryMessages(msg_id: string) {
        if(!msg_id.length) return 
    
        return request({
            url: getMessagesUrl(`/${msg_id}/refresh_summary`),
            method: 'GET',
            headers: await headers()
        })
    },

    async getSummaryExtra(msg_id: TMessageId) {
        if(msg_id === undefined || msg_id === null) return 

        return request<IGetSummaryExtraResponse>({
            url: getMessagesUrl(`/summary_extra?esid=${msg_id}`),
            method: 'GET',
            headers: await headers()
        })
    },
    
    // !DELETE !CHECK !TEST
	// !--------------------------------------------------------
    async getHighlights(refresh_from_llm = false, unread = true) {
        return request<IGetHighlightResponse>({
            url: getMessagesUrl(`/highlights${refresh_from_llm ? `?refresh_from_llm=true&unread=${unread}` : '' }`),
            method: 'GET',
            headers: await headers()
        })
    },
    // !--------------------------------------------------------
    
    
    // !DELETE !CHECK !TEST
    // !--------------------------------------------------------
    async getDetailedMessage(msg_id: TMessageId): Promise<IGetDetailedMessageResponse | {}> {
        if(!msg_id) return {}

        return request<IGetDetailedMessageResponse>({
            url: getMessagesUrl(`/${String(msg_id)}/summary`),
            method: 'GET',
            headers: await headers()
        })
    },
    // !--------------------------------------------------------
    
    
    async getMessages() {
        return request({
            url: getMessagesUrl(`/list`),
            method: 'GET',
            headers: await headers()
        })
    },
    
}