import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { Body, Button, ButtonPlain, Header, Loader, Typography } from '@/components/ui'
import { StyledProps, useColorScheme } from 'nativewind'
import { useColors } from '@/hooks/useColors'
import { useGetSummaryMessages } from './api'
import cn from 'clsx'
import { useSummaryState } from './hooks/useSummaryState.hook'
import { TMessageId } from './types'

interface IProps {
    className?: StyledProps<string>
    selectMessage: (msgId: TMessageId) => void
}

const tags = [
    { key: 'all', title: 'All' },
    { key: 'work', title: 'Work' },
    { key: 'university', title: 'University' },
    { key: 'required_actions', title: 'Required actions' },
    { key: 'other', title: 'Other' },
    { key: 'item_orange', title: 'Item Orange' }
]

const ExecutiveSummary: FC<IProps> = ({ className, selectMessage }) => {
    const { bg, text } = useColors()

    const { colorScheme } = useColorScheme()
    const deviderColor = colorScheme === 'dark' ? 'border-devider-dark' : 'border-devider-light'

    const [selectedDigestTag, setSelectedDigestTag] = useState<string>('all')

    const { mailsCount, body, refresh, isFetching, setEnabled } = useGetSummaryMessages()
    const { summaryCount, summaryState, setSummaryCount } = useSummaryState(body, { count: 10 })

    useEffect(() => {
        !body.length && fetchSummaryMessages()
    }, [body])

    const fetchSummaryMessages = (refreshMessages = false) => {
        if (summaryCount !== 0) {
            setEnabled(true)
            if (refreshMessages) {
                refresh(refreshMessages)
                summaryCount > 0 ? setSummaryCount((count) => count - 1) : setSummaryCount(0)
            }
        }
    }

    const handleScrollWheel = (event: { deltaY: any }, scrollViewRef: MutableRefObject<null>) => {
        if (scrollViewRef.current) {
            //@ts-ignore
            scrollViewRef.current.scrollTo({
                //@ts-ignore
                x: scrollViewRef.current.getScrollableNode().scrollLeft + event.deltaY,
                animated: true
            })
        }
    }
    const scrollViewRef = useRef(null)

    const showTags = !isFetching && summaryCount !== 0
    return (
        <View
            className={cn(
                'pt-base-x5 pb-base-x2 pl-base-x2 min-h-[264px] max-h-[400px] justify-between border-b-[1px] overflow-hidden',
                deviderColor,
                className
            )}>
            <Header title='Scive Digest AI' subtitle='' counter={{ count: mailsCount, subject: 'new' }} />
            {showTags && (
                <View
                    className='flex flex-row pt-4 pb-2 ml-6 w-full overflow-hidden'
                    //@ts-ignore
                    onWheel={(event) => handleScrollWheel(event, scrollViewRef)}>
                    <ScrollView
                        ref={scrollViewRef}
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        contentContainerStyle={{ gap: 4 }}>
                        {tags.map((tag) => {
                            const selected = selectedDigestTag === tag.key
                            return (
                                <TouchableOpacity
                                    key={tag.key}
                                    activeOpacity={1}
                                    className={cn('py-[2px] px-4 rounded', bg.inactiveSurface, {
                                        'bg-primary': selected
                                    })}
                                    onPress={() => setSelectedDigestTag(tag.key)}>
                                    <Typography
                                        variant='body'
                                        className={cn('text-base-body2 leading-4', {
                                            'text-white': selected
                                        })}>
                                        {tag.title}
                                    </Typography>
                                </TouchableOpacity>
                            )
                        })}
                    </ScrollView>
                </View>
            )}

            {!isFetching ? (
                <Body
                    marginTop={!showTags}
                    body={body}
                    selectMessage={selectMessage}
                    placeholder='Hello! Here you will see an Executive Summary of your new emails. Click on the number to view the message each part refers to.'
                    status={summaryState}
                />
            ) : (
                <Body>
                    <Loader text='Refreshing executive summary' />
                </Body>
            )}
            <View className='self-end flex-row gap-x-base-x6 pr-base-x2 pt-base-x2'>
                <ButtonPlain
                    className='h-base-x8'
                    classNameText={cn('font-semibold', !body && text.inactiveText)}>
                    Mark all as read
                </ButtonPlain>
                <Button
                    className='pl-base-x2 pr-base-x2 flex-row items-center'
                    classNameText={cn(summaryCount ? text.black : 'text-red')}
                    variant='button-plain'
                    styleTextOnHover='black'
                    icon='Wand2'
                    noMargin
                    sizeIcon={20}
                    strokeWidthIcon={2}
                    classNameIcon='p-base-x1'
                    colorIcon={summaryCount ? 'black' : 'red'}
                    onPress={() => fetchSummaryMessages(true)}>
                    Refresh ({summaryCount} left)
                </Button>
            </View>
        </View>
    )
}

export default ExecutiveSummary
