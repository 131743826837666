import { View, Text, Pressable, Image } from 'react-native'
import React, { Dispatch, FC } from 'react'
import Icon from '../icon/Icon'
import { useColorScheme } from 'nativewind'
import { useColors } from '@/hooks/useColors'

interface IProps {
    visibleScive: Dispatch<React.SetStateAction<boolean>>
    isSelected: Dispatch<React.SetStateAction<boolean>>
    isVisible: boolean
}

const RowActions: FC<IProps> = ({visibleScive, isVisible, isSelected }) => {
    const { colorScheme } = useColorScheme()
    const { color} = useColors()

    const icons = ['MailOpen', 'XOctagon', 'Trash2']

    return (
        <View
            className='pl-base-x4 right-0 pr-base-x1 z-20 flex-row items-center '
            style={{ gap: 8 }}>
            <Pressable className='p-base-x1' onPress={() => visibleScive(!isVisible)}>
                <Image
                    source={require(`@/assets/icons/scive-${colorScheme}.svg`)}
                    className='h-base-x5 w-base-x5'
                />
            </Pressable>
            {icons.map((name, idx) => (
                <Icon
                    key={idx}
                    name={name}
                    size={20}
                    color={color.black}
                    className='p-base-x1'
                    onPress={() => isSelected(true)}
                    
                />
            ))}
        </View>
    )
}

export default RowActions