import { FC } from 'react'
import { View } from 'react-native'
import { Body, Header, Loader, Typography } from '@/components/ui'
import { StyledProps } from 'nativewind'
import cn from 'clsx'
import { useColors } from '@/hooks/useColors'
import { useStore } from '@/shared/store'

interface IProps {
	className?: StyledProps<string>
	isLoading?: boolean | null
}

const Highlights: FC<IProps> = ({ className, isLoading }) => {
	const { highlights } = useStore((state) => state.summaryExtra)
	const { text } = useColors()
	return (
		<View className={cn('py-5 min-h-[280px] max-h-[308px] box-border', className)} style={{ gap: 24}}>
			<Header title='Highlights' />
			<Body
				placeholder='Here you will see details of selected email chain'
				className='max-h-[260px] pl-4 pr-2 mr-2'
				style={{ gap: 8}}
				marginTop={false}
			>
				{
					!isLoading 
						? highlights.length ? (
							highlights.map((highlight, key) => (
								<View key={key}>
									<Typography variant='body' className={cn('leading-5 py-1 pl-4 pr-2', text.boldText)} >
										{key + 1}. {highlight.text}
									</Typography>
								</View>
							))
						) : (<Typography variant='body' className={cn(text.inactiveText)}>Here you will see details of selected email chain</Typography>)
						: <Loader text='Generate highlights' />
				}
			</Body>
		</View>
	)
}

export default Highlights
