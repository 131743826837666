import { FC, useState } from 'react'
import { Image, View } from 'react-native'
import { Button, Dropdown, Typography } from '@/components/ui'
import cn from 'clsx'
import { useMailPosition } from '@/context/MailPositionContext'
import { useColors } from '@/hooks/useColors'
import { useColorScheme } from 'nativewind'

interface IProps {
	title: string
	sender: string
	senderAddress: string
	date: string
}

const Header: FC<IProps> = ({ title, sender, senderAddress, date }) => {
	const { position } = useMailPosition()
	const { colorScheme } = useColorScheme()
	const { text, color, border, bg } = useColors()
	const [dropdownVisible, setDropdownVisible] = useState(false)
	const [valueMoreMail, setValueMoreMail] = useState('')
	const [isImportant, setIsImportant] = useState(false)

	const toggleDropdown = () => {
		setDropdownVisible(!dropdownVisible)
	}
	const closeDropdown = () => {
		setDropdownVisible(false)
	}

	return (
        <View
            className={cn(
                'pl-base-x4 pr-base-x2 pt-base-x1 relative z-50 pb-base-x1 items-center justify-between flex-row border-b ',
                border.dividerLight
            )}>
            <View className='flex-col justify-between '>
                <View className='flex-row items-center' style={{ gap: 8 }}>
                    <Typography variant='h3' className={cn(text.boldText)}>
                        {title}
                    </Typography>
                    <Button
                        className='w-base-x9 h-base-x9 rounded-base-x16 flex items-center justify-center'
                        styleOnHover={bg.hoverIconSurface}
                        onPress={() => setIsImportant(!isImportant)}>
                        <Image
                            source={require('@/assets/icons/important.svg')}
                            tintColor={isImportant ? '#FDBA74' : colorScheme === 'dark' ? '#374151' : ''}
                            className='h-[14px] w-[15px]'
                        />
                    </Button>
                </View>
                {position === 'horizontal' && (
                    <View className='pt-base-x2 pb-base-x2 flex-row' style={{ gap: 16 }}>
                        <Typography variant='button-default' className={text.boldText}>
                            {sender}
                        </Typography>
                        <Typography variant='calout' className='text-light-text'>
                            {senderAddress}
                        </Typography>
                    </View>
                )}
            </View>
            <View className='flex-col'>
                <View className='flex-row items-center' style={{ gap: 16 }}>
                    <Dropdown
                        isVisible={dropdownVisible}
                        setValue={setValueMoreMail}
                        onClose={closeDropdown}
                        options={[
                            { icon: 'Reply', text: 'Forward', reverseIcon: true },
                            { icon: 'ReplyAll', text: 'Forward all', reverseIcon: true },
                            { icon: 'AlertOctagon', text: 'Report spam' },
                            { icon: 'MailOpen', text: 'Mark as read' },
                            { icon: 'Trash2', text: 'Delete' }
                        ]}
                        button={
                            <Button
                                onPress={toggleDropdown}
                                icon='MoreVertical'
                                className='p-base-x2 rounded-base-x16 relative'
                                styleOnHover={bg.hoverIconSurface}
                                colorIcon={color.black}
                            />
                        }
                    />
                </View>
                {position === 'horizontal' && (
                    <Typography variant='label-date' className='pt-base-x2 pb-base-x2 text-right '>
                        {date}
                    </Typography>
                )}
            </View>
        </View>
    )
}

export default Header
